import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  AuthContent,
  AuthModal,
  AuthTitle,
  ErrorText,
  FlexRow,
  Page,
  PassIcon,
  StyledLink as StyledLinkDef,
  StyledRegisterButton as StyledRegisterButtonDef,
  HalfContent,
  AuthWrapper,
  AuthInputWrapper as AuthInputWrapperDef,
  FlexColumn,
  InputTitle,
  InputAndErrorWrapper,
} from '../style/projectComponents'
import {
  StyledRegisterButton as StyledRegisterButtonTime,
  AuthInputWrapper as AuthInputWrapperTime,
  StyledLink as StyledLinkTime,
} from '../style/projectTimeComponents'
import { observer } from 'mobx-react-lite'
import { ValidationInputComponent } from '../components/global/ValidationInputComponent'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import AuthApi from '../service/auth-api/AuthApi'
import { IAuthSigninRequest } from '../service/auth-api/models'
import UserStorage from '../storage/UserStorage/UserStorage'
import jwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import GlobalModalStore from '../storage/GlobalModalStore/GlobalModalStore'
import LeftContentComponent from '../components/auth/Content/LeftContentComponent'
import OtpInput from '../components/global/OtpInput'
import { PROJECT_NAME } from '../config/serverConstants'

const StyledRegisterButton =
  PROJECT_NAME === 'Def' ? StyledRegisterButtonDef : StyledRegisterButtonTime

const AuthInputWrapper = PROJECT_NAME === 'Def' ? AuthInputWrapperDef : AuthInputWrapperTime
const StyledLink = PROJECT_NAME === 'Def' ? StyledLinkDef : StyledLinkTime
const StyledPage = styled(Page)`
  background-position: center;
  background-size: cover;
  justify-content: start;

  .header {
    position: absolute;
  }
`
const InputsBlock = styled(FlexColumn)`
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.gray050};
  padding: 20px 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 20px 10px;
    width: calc(100% - 40px);
  }
`

const AuthPage: React.FC = () => {
  const UserStore = useContext(UserStorage)
  const {
    control,
    clearErrors,
    register,
    watch,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const GlobalModalStorage = useContext(GlobalModalStore)
  const [authStage, setAuthStage] = useState(0)
  const [otp, setOtp] = useState('')

  const AuthMutation = useMutation((data: IAuthSigninRequest) => AuthApi.signin(data), {
    onError: error => {
      setError('badSignin', {
        type: 'manual',
        message: 'auth.errors.password.serverError',
      })
      GlobalModalStorage.makeVisible(false, t('auth.errors.password.serverError'))
    },
    onSuccess: data => {
      if (data?.requiresTwoFactor) {
        setAuthStage(1)
      } else {
        UserStore.setTokens({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        })
        UserStore.setUser(jwtDecode(data.accessToken))
        navigate('/dashboard')
      }
    },
  })

  const onSubmit = data => {
    if (authStage === 0) {
      AuthMutation.mutate({
        email: data.email,
        password: data.password,
      })
    } else {
      AuthMutation.mutate({
        email: data.email,
        password: data.password,
        twoFactorCode: otp,
      })
    }
  }

  const [showPass, setShowPass] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledPage>
        <AuthContent>
          <AuthWrapper>
            <LeftContentComponent />
            <HalfContent>
              <AuthModal>
                <AuthTitle>{t('auth.title')}</AuthTitle>
                {authStage === 0 && (
                  <InputsBlock>
                    <InputAndErrorWrapper style={{ marginBottom: 10 }}>
                      <InputTitle>Email</InputTitle>
                      <AuthInputWrapper style={{ marginBottom: 5 }}>
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'auth.errors.email.required',
                            },
                            pattern: {
                              value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                              message: 'auth.errors.email.pattern',
                            },
                          }}
                          render={({ field: { ref, ...field } }) => (
                            <ValidationInputComponent
                              {...field}
                              isError={!!errors.email}
                              label={t('auth.email')}
                            />
                          )}
                        />
                      </AuthInputWrapper>
                      <ErrorText hasError={!!errors.email}>
                        {t(errors?.email?.message.toString())}
                      </ErrorText>
                    </InputAndErrorWrapper>
                    <InputAndErrorWrapper>
                      <InputTitle>Password</InputTitle>
                      <AuthInputWrapper style={{ marginBottom: 5 }}>
                        <Controller
                          name="password"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'auth.errors.password.required',
                            },
                          }}
                          render={({ field: { ref, ...field } }) => (
                            <ValidationInputComponent
                              {...field}
                              type={showPass ? 'text' : 'password'}
                              isError={!!errors.password}
                              label={t('auth.password')}
                            />
                          )}
                        />
                        <PassIcon
                          onClick={() => setShowPass(prev => !prev)}
                          src={showPass ? '/images/show.svg' : '/images/hide.svg'}
                        />
                      </AuthInputWrapper>
                      <ErrorText hasError={!!errors.password}>
                        {t(errors?.password?.message.toString())}
                      </ErrorText>
                    </InputAndErrorWrapper>

                    <StyledLink
                      to={'/forgot'}
                      style={{
                        cursor: 'pointer',
                        color: 'gray',
                        alignSelf: 'end',
                        marginBottom: '5px',
                        marginRight: '25px',
                        fontSize: 12,
                      }}
                    >
                      {t('common.forgotPassword')}
                    </StyledLink>
                    <StyledRegisterButton
                      disabled={AuthMutation.isLoading}
                      onClick={() => clearErrors()}
                      type={'submit'}
                    >
                      {t('auth.login')}
                    </StyledRegisterButton>
                  </InputsBlock>
                )}
                {authStage === 1 && (
                  <InputsBlock>
                    <p style={{ marginBottom: 16, fontSize: 16, marginTop: 8 }}>
                      {t('auth.enterYour2fa')}
                    </p>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span style={{ marginRight: 8 }} />}
                      inputStyle={{
                        height: 50,
                        width: 24,
                        fontSize: 20,
                        padding: '0 14px',
                        border: `1px solid ${({ theme }) => theme.colors.mediumGray}`,
                        borderRadius: 12,
                        marginBottom: 20,
                      }}
                      renderInput={props => <input {...props} />}
                    />
                    <StyledRegisterButton
                      disabled={AuthMutation.isLoading && otp?.length !== 6}
                      onClick={() => clearErrors()}
                      type={'submit'}
                    >
                      {t('auth.login')}
                    </StyledRegisterButton>
                  </InputsBlock>
                )}

                <ErrorText style={{ marginTop: 10 }} hasError={!!errors.badSignin}>
                  {t(errors?.badSignin?.message.toString())}
                </ErrorText>

                <FlexRow style={{ marginTop: 32 }}>
                  <p style={{ fontSize: 14 }}>{t('auth.not_registered')}</p>
                  <StyledLink to={'/register'} style={{ marginLeft: 12 }}>
                    {t('auth.create_acc')}
                  </StyledLink>
                </FlexRow>
              </AuthModal>
            </HalfContent>
          </AuthWrapper>
        </AuthContent>
      </StyledPage>
    </form>
  )
}

export default observer(AuthPage)
