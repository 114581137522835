import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  StyledButton as StyledButtonDef,
  StyledLink,
} from '../../../style/projectComponents'
import { StyledButton as StyledButtonTime } from '../../../style/projectTimeComponents'
import { Link as ScrollLink } from 'react-scroll'
import styled from 'styled-components/macro'
import React, { ComponentPropsWithoutRef, FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { adminLinks, headerAuthLinks } from '../headerLinks'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdminPercent } from '../../../pages/AdminPages/AdminPercent'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import { observer } from 'mobx-react-lite'
import { LogoutModal } from './LogoutModal'
import { AdminCreateInvestPool } from '../../../pages/AdminPages/AdminCreateInvestPool'
import { PROJECT_NAME } from '../../../config/serverConstants'

const StyledButton = PROJECT_NAME === 'Def' ? StyledButtonDef : StyledButtonTime
const Box = styled(FlexColumnStart)<{ $isAuth?: boolean; isOpen?: boolean }>`
  position: ${({ $isAuth, isOpen }) => ($isAuth && isOpen ? 'fixed' : 'absolute')};
  width: ${props => (props.$isAuth && props.isOpen ? '100vw' : '')};
  height: ${props => (props.$isAuth && props.isOpen ? '100vh' : '')};
  bottom: ${props => (props.$isAuth ? '0' : '')};
  right: ${props => (props.$isAuth ? '0' : '')};
  left: ${props => (props.$isAuth ? '0' : '')};
  top: ${props => (props.$isAuth ? '0' : '')};
  z-index: -100;

  @media (max-width: 600px) {
    z-index: -1;
    width: 100%;
  }
`

const Wrapper = styled(FlexColumnStart)<{
  $isAuth?: boolean
  open?: boolean
  isScrolled?: boolean
  $isAdmin?: boolean
}>`
  position: fixed;
  transform: translateX(${({ open }) => (open ? '0' : '150%')});
  height: 100vh;
  overflow: auto;
  background-color: #000;
  z-index: 100;
  width: 376px;
  padding: ${({ $isAdmin }) => ($isAdmin ? '64px 24px' : '24px 24px')};
  right: 0;
  top: 0;
  justify-content: ${({ $isAdmin }) => ($isAdmin ? 'flex-start' : 'center')};
  @media (max-width: 600px) {
    left: 0;
    top: 0;
    /* padding: 20px; */
    height: calc(100%);
    border-radius: 0;
    z-index: -1;
    width: auto;
    min-width: 91%;
    border-left: none;
    align-items: center;
  }
`

const CustomStyledLink = styled(StyledLink)<{
  mini?: boolean
  $isAuth?: boolean
  $isSelected?: boolean
}>`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 110%;
  transition: 0.5s;
  background: ${({ $isSelected }) =>
    $isSelected
      ? PROJECT_NAME === 'Def'
        ? 'linear-gradient(84.75deg, #FF8A00 -55.87%, #D449F7 40.4%)'
        : '#58d7d6'
      : 'none'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${({ $isSelected }) => ($isSelected ? 'transparent' : '#fff')};

  &:hover {
    background: ${PROJECT_NAME === 'Def'
      ? 'linear-gradient(84.75deg, #ff8a00 -55.87%, #d449f7 40.4%)'
      : '#58d7d6'};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &[data-admin-btn] {
    flex-direction: row;
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      font-size: 20px;
    }

    p {
      padding-right: 10px;
    }
  }

  @media (max-width: 600px) {
    font-size: 20px;
  }
`

export type IMenuModalProps = {
  isAuth?: boolean
  open: boolean
  handleMenu: () => void
  isScrolled?: boolean
  toggleTheme: any
  isDarkTheme: boolean
}

const ExitBtn = styled(FlexRow)<{ $isAuth: boolean }>`
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  align-items: center;

  @media (max-width: 600px) {
    font-size: 20px;
  }

  span {
    color: ${({ theme }) => theme.colors.redFail};
  }

  :hover {
  }
`

const BtnWrapper = styled(FlexColumn)`
  gap: 1rem;
  margin-top: 3.75rem;
  width: 100%;
  align-items: center;
  justify-content: center;

  button {
    width: 100%;
  }

  @media (min-width: 768px) {
    display: none;
  }
`

const StyledScrollLink = styled(ScrollLink)<{
  $isAuth?: boolean
  mini?: boolean
}>`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 20px;
  font-weight: ${props => (props.$isAuth && '400') || (!props.mini && '300')};
  line-height: 110%;
  cursor: pointer;

  @media (max-height: 780px) {
    font-size: 20px;
  }

  @media (max-width: 600px) {
    font-size: 20px;
  }
`

const ColumnMenu = styled(FlexColumnStart)`
  gap: 1.875rem;
`

const MenuModal: FC<IMenuModalProps & ComponentPropsWithoutRef<'div'>> = props => {
  const { open, handleMenu, isAuth, toggleTheme, isDarkTheme, ...other } = props
  const { t } = useTranslation()
  const location = useLocation()
  const isAdminPaths = location.pathname.includes('admin')
  const [visiblePercent, setVisiblePercent] = useState<boolean>(false)
  const [createInvestPool, setCreateInvestPool] = useState<boolean>(false)
  const [visibleLogout, setVisibleLogout] = useState<boolean>(false)
  const UserStore = useContext(UserStorage)
  const isAdmin = UserStore.isAdmin()
  const navigate = useNavigate()

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'auto'
    }
  }, [open])

  return (
    <>
      <Box
        isOpen={open}
        onClick={e => {
          if (e.currentTarget === e.target) {
            if (open) {
              handleMenu()
            }
          }
        }}
        $isAuth={isAuth}
      >
        <Wrapper $isAuth={isAuth} $isAdmin={isAdmin} open={open} {...other}>
          <ColumnMenu>
            {(isAuth && isAdminPaths ? adminLinks : headerAuthLinks).map((it, idx) =>
              isAuth ? (
                <React.Fragment key={idx}>
                  {it?.to && (
                    <CustomStyledLink
                      to={it?.to || '/'}
                      key={idx}
                      $isAuth={isAuth}
                      onClick={() => handleMenu()}
                      $isSelected={location.pathname === it.to}
                    >
                      {t(it.text)}
                    </CustomStyledLink>
                  )}
                </React.Fragment>
              ) : (
                <StyledScrollLink
                  onClick={() => handleMenu()}
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  to={it?.to || '/'}
                  key={idx}
                >
                  {t(it.text)}
                </StyledScrollLink>
              )
            )}

            {isAdminPaths && (
              <>
                <StyledButton
                  onClick={() => {
                    setVisiblePercent(true)
                    handleMenu()
                  }}
                  style={{ padding: 'var(--16, 1rem)', width: '100%' }}
                >
                  {t('adminPercent.accrualInterest')}
                </StyledButton>
                <StyledButton
                  variant="black"
                  onClick={() => {
                    setCreateInvestPool(true)
                    handleMenu()
                  }}
                  style={{ padding: 'var(--16, 1rem)', width: '100%' }}
                >
                  Create Invest Pool
                </StyledButton>
              </>
            )}

            {isAuth && isAdmin && (
              <CustomStyledLink
                data-admin-btn
                to={!isAdminPaths ? '/admin/analytics' : '/dashboard'}
                onClick={() => handleMenu()}
              >
                <p>{t('menu.adminPanel')}</p>
                <img src={'/images/door.svg'} alt="" />
              </CustomStyledLink>
            )}

            {isAuth && (
              <ExitBtn onClick={() => setVisibleLogout(true)} $isAuth>
                <span>{t('menu.logout')}</span>
              </ExitBtn>
            )}
          </ColumnMenu>

          {!isAuth && (
            <BtnWrapper>
              <StyledButton onClick={() => navigate('/register')}>
                {t('auth.reg_title')}
              </StyledButton>

              <StyledButton
                style={{
                  height: 'auto',
                  minHeight: 25,
                }}
                onClick={() => navigate('/login')}
                variant={'text'}
              >
                {t('auth.login')}
              </StyledButton>
            </BtnWrapper>
          )}
        </Wrapper>
      </Box>
      {isAdminPaths && visiblePercent && <AdminPercent setIsOpen={setVisiblePercent} />}
      {isAdminPaths && createInvestPool && (
        <AdminCreateInvestPool setIsOpen={setCreateInvestPool} />
      )}
      {visibleLogout && <LogoutModal setIsOpen={setVisibleLogout} />}
    </>
  )
}

export default observer(MenuModal)
