export const formatNumber = (number: number, fractions?: number) => {
  const suffixes = ['', 'K', 'M', 'B', 'T'] // можно расширить по необходимости
  let suffixIndex = 0

  while (number >= 1000 && suffixIndex < suffixes.length - 1) {
    number /= 1000
    suffixIndex++
  }

  const formattedNumber = number.toFixed(fractions || 2).replace(/\.00$/, '') // убираем ".00" если они есть
  const suffix = suffixes[suffixIndex]

  return `${formattedNumber}${suffix}`
}
