export const isWalletGood = (pattern: any) => {
  return {
    required: {
      value: true,
      message: 'withdraw.errors.wallet.required',
    },
    pattern: {
      value: pattern,
      message: 'withdraw.errors.wallet.pattern',
    },
  }
}

export const isAmountGood = (userBalance: number) => {
  return {
    required: {
      value: true,
      message: 'withdraw.errors.amount.required',
    },
    pattern: {
      value: /[0-9]{1,}$/g,
      message: 'withdraw.errors.amount.pattern',
    },
    min: {
      value: 0.00000001,
      message: 'withdraw.errors.amount.min',
    },
    validate: {
      maxOrEqual: value => {
        return value <= userBalance || 'withdraw.errors.amount.max'
      },
    },
    valueAsNumber: true,
  }
}

export const isOrderAmountGood = (value: string): boolean => {
  const amount = parseFloat(value)
  if (isNaN(amount) || amount <= 0) {
    return false
  }
  const decimalPart = (amount % 1).toFixed(10).substring(2)
  if (decimalPart.length !== 8) {
    return false
  }
  return true
}

export const isUsdOrderAmountGood = (value: string): boolean => {
  const amount = parseFloat(value)
  if (isNaN(amount) || amount <= 0) {
    return false
  }
  const decimalPart = (amount % 1).toFixed(10).substring(2)
  if (decimalPart.length !== 2) {
    return false
  }
  return true
}
