import React from 'react'
import { FlexColumn, FlexRow } from '../../style/projectComponents'
import styled from 'styled-components/macro'

const Block = styled(FlexColumn)`
  height: 170px;
  background: #111111;
  width: 100vw;

  h1 {
    color: #fff;
    padding: 0;
    margin: 0;
    margin-bottom: 16px;
  }

  p {
    color: #fff;
    font-size: 18px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`

const PageHeader: React.FC = () => {
  return (
    <Block>
      <h1>Convert</h1>
      <FlexRow style={{ gap: 12, flexWrap: 'wrap' }}>
        <p>Lower threshold</p>
        <img src="/images/interface/ellipse.svg" alt="" width={5} />
        <p>Simple transactions</p>
      </FlexRow>
    </Block>
  )
}

export default PageHeader
