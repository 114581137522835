import React, { useContext, useState } from 'react'
import styled from 'styled-components/macro'
import {
  FlexColumn,
  FlexRow,
  FlexRowBetween,
  DepositTabsButton as DepositTabsButtonDef,
} from '../../style/projectComponents'
import { DepositTabsButton as DepositTabsButtonTime } from '../../style/projectTimeComponents'
import ProfileStorage from '../../storage/ProfileStorage/ProfileStorage'
import toLocaleNumber from '../../utils/toLocaleNumber'
import { useTranslation } from 'react-i18next'
import { WithdrawWindow } from '../WithdrawComponents/WithdrawWindow'

import { AvailableNetworksForDeposit } from '../../service/admin-api/models'
import { DepositWindow } from '../DepositCompnents/DepositWindow'
import { PROJECT_NAME } from '../../config/serverConstants'

const DepositTabsButton = PROJECT_NAME === 'Def' ? DepositTabsButtonDef : DepositTabsButtonTime
const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding: 14px 0;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
  background: none;
  width: 100%;

  /* @media (max-width: 800px) {
    width: calc(100vw - 20px);
  } */

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
`
const ShortName = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
  width: 100%;
`
const FullName = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
  width: 100%;
`

interface AssetActionsItemProps {
  usdPrice: number
  title: string
  fullName: string
  id: string
  networks: AvailableNetworksForDeposit[]
  userBalance: number
  isAdmin?: boolean
  currencySymbol?: string
}

export const AssetActionsItem: React.FC<AssetActionsItemProps> = ({
  usdPrice,
  title,
  fullName,
  id,
  networks,
  userBalance,
  isAdmin,
  currencySymbol,
}) => {
  const icon = `/images/coins/${title.toLowerCase()}.png`
  const [activeTab, setActiveTab] = useState(null)
  const updateActiveTab = (setTab: string) => {
    if (activeTab && activeTab === setTab) {
      setActiveTab(null)
    } else setActiveTab(setTab)
  }
  const ProfileStore = useContext(ProfileStorage)
  const walletId = ProfileStore?.profile?.wallets?.find(
    it => it.currency.toLowerCase() === title.toLowerCase()
  )?.id
  const userBalanceUsdValue = userBalance * usdPrice || 0
  const { t } = useTranslation()
  // id == 0 && for Block style condition
  return (
    <Block style={{ borderTop: `1px solid ${({ theme }) => theme.colors.blueGray}` }}>
      <FlexRowBetween style={{ gap: '10px' }}>
        <FlexRow style={{ minWidth: '110px', width: 110, justifyContent: 'start' }}>
          <img src={icon} alt="" style={{ borderRadius: '100%', objectFit: 'contain' }} />
          <FlexColumn style={{ justifyContent: 'start' }}>
            <ShortName>{title}</ShortName>
            <FullName>{fullName}</FullName>
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn style={{ width: 130, justifyContent: 'start' }}>
            <ShortName>{toLocaleNumber(userBalance, 0, 8) || 0}</ShortName>
            <FullName>
              {currencySymbol || '$'} {toLocaleNumber(userBalanceUsdValue)}
            </FullName>
          </FlexColumn>
        </FlexRow>
        <FlexRow style={{ width: 175, justifyContent: 'end' }}>
          {!isAdmin && (
            <DepositTabsButton
              onClick={() => {
                updateActiveTab('DEPOSIT')
              }}
              clicked={activeTab === 'DEPOSIT'}
              style={{ marginRight: 12 }}
            >
              {t('assets.deposit')}
            </DepositTabsButton>
          )}

          {!isAdmin && (
            <DepositTabsButton
              onClick={() => updateActiveTab('WITHDRAW')}
              clicked={activeTab === 'WITHDRAW'}
            >
              {t('assets.withdraw')}
            </DepositTabsButton>
          )}
        </FlexRow>
      </FlexRowBetween>

      {activeTab === 'DEPOSIT' && <DepositWindow title={title} networks={networks} />}
      {activeTab === 'WITHDRAW' && (
        <WithdrawWindow
          walletId={walletId}
          userBalance={userBalance}
          title={title}
          networks={networks}
        />
      )}
    </Block>
  )
}
