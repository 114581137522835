import React from 'react'
import styled from 'styled-components/macro'
import { FlexColumn } from '../../style/projectComponents'

const SetPhotoButtonWrapper = styled(FlexColumn)`
  width: 120px;
  height: 120px;
  border-radius: 16px;
  margin-bottom: 22px;
  cursor: pointer;
  background-size: cover;
  border: 1px solid ${({ theme }) => theme.colors.modalBg};
`
const SetPhotoButton = styled.input`
  width: 120px;
  height: 120px;
  opacity: 0;
  cursor: pointer;
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

interface SetImageComponentProps {
  fileUrl: string
  handleChangeFile: any
  fullSize?: boolean
}

export const SetImageComponent: React.FC<SetImageComponentProps> = ({
  fileUrl,
  handleChangeFile,
  fullSize,
}) => {
  return (
    <SetPhotoButtonWrapper
      style={{
        backgroundColor: 'black',
        backgroundImage: fileUrl ? `url(${fileUrl})` : 'url(/images/buttons/upload-image.png)',
        backgroundSize: '200px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: fullSize && '100%',
      }}
    >
      <SetPhotoButton accept={'.png,.jpg,.jpeg'} onChange={handleChangeFile} type={'file'} />
    </SetPhotoButtonWrapper>
  )
}
