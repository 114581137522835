import styled from 'styled-components/macro'
import { StyledSection } from '../../global/StyledSection'
import {
  AppTextBlack,
  FlexColumn,
  FlexColumnStart,
  FlexRow,
} from '../../../style/projectComponents'
import { MenuColItem } from './MenuColItem'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import { socialLinks } from '../../../config/socialLinks'
import { HeaderImgDef } from '../../global/HeaderComponent/images/HeaderImgDef'
import { HeaderImgTime } from '../../global/HeaderComponent/images/HeaderImgTime'
import { PROJECT_NAME } from '../../../config/serverConstants'

const FooterSectionPage = styled(StyledSection)`
  max-width: 100vw;
  align-items: center;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  background: rgb(18, 18, 18);
`

const BordersContent = styled(FlexColumn)`
  max-width: 120rem;
  align-items: center;
  position: relative;
  border-top-style: none;
  border-bottom-style: none;
  width: 100%;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  box-sizing: border-box;
`

const FooterContent = styled(FlexRow)`
  width: 100%;
  align-items: flex-start;
  column-gap: 3.125rem;
  justify-content: space-between;
  row-gap: 2.125rem;

  @media (max-width: 1350px) {
    flex-direction: column;
  }
`

const LogoWrapper = styled(FlexColumnStart)`
  text-align: left;

  p {
    font-size: 0.875rem;
    margin-top: 1rem;
    color: #aab0b8;
  }
  img {
    width: 100px;
  }
`

const MenuWrapper = styled(FlexColumnStart)`
  span {
    font-size: 0.875rem;
    margin-bottom: 1rem;
    color: #aab0b8;
  }

  .column_menu {
    row-gap: 1.5rem;
  }

  @media (min-width: 950px) and (max-width: 1350px) {
    margin-left: auto;
  }
`

const MenuAndLinks = styled(FlexRow)`
  width: 100%;
  gap: 8rem;
  justify-content: unset;

  @media (max-width: 950px) {
    flex-direction: column;
    gap: 2rem;
    align-items: baseline;
  }

  @media (max-width: 1350px) {
    padding-bottom: 3.75rem;
    align-items: start;
  }
`

const Inner = styled(FlexColumnStart)`
  gap: 2.125rem;
  max-width: 81.25rem;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  padding: 0rem 1.25rem;
`

const Menu = styled(FlexRow)`
  @media (max-width: 1350px) {
    gap: 1.5rem;
    justify-content: flex-start;
    align-items: baseline;
  }
`
const SocialLinkImage = styled.img`
  cursor: pointer;
  height: 20px;
  margin: 0 10px;
`

const Images = {
  Def: HeaderImgDef,
  Time: HeaderImgTime,
}
export const Footer = () => {
  const location = useLocation()
  const isLandingPage = location.pathname === '/'
  const hideFooter = location.pathname === '/login' || location.pathname === '/register'

  const { t } = useTranslation()

  const headerAuthLinks = [
    {
      link: '/dashboard',
      title: t('menu.dashboard'),
    },
    {
      link: '/assets',
      title: t('menu.deposit'),
    },
    {
      link: '/settings',
      title: t('menu.settings'),
    },
  ]

  const UserStore = useContext(UserStorage)
  const isAuth = !!UserStore.accessToken

  return (
    <FooterSectionPage style={{ display: hideFooter && 'none' }}>
      <BordersContent>
        <Inner>
          <FooterContent>
            <MenuAndLinks>
              <LogoWrapper>
                <img src={Images[PROJECT_NAME].logo} alt="logo" />
                {PROJECT_NAME === 'Def' ? (
                  <p>© 2024 Defence Investments</p>
                ) : (
                  <p>© 2024 Time Investments</p>
                )}
              </LogoWrapper>

              <MenuWrapper>
                <Menu style={{ gap: '1.5rem' }}>
                  {!isLandingPage && isAuth && <MenuColItem items={headerAuthLinks} />}
                </Menu>
              </MenuWrapper>
            </MenuAndLinks>
          </FooterContent>
        </Inner>
        <Inner style={{ marginTop: 48 }}>
          <FlexRow>
            <AppTextBlack style={{ color: '#fff', marginRight: 32 }}>
              {t('footer.community')}
            </AppTextBlack>
            {socialLinks.map((linkItem, idx) => (
              <a href={linkItem.link} rel="noreferrer" target="_blank" key={idx}>
                <SocialLinkImage src={linkItem.image} key={linkItem.link} alt="" />
              </a>
            ))}
          </FlexRow>
        </Inner>
      </BordersContent>
    </FooterSectionPage>
  )
}
