import { FlexColumnStart } from '../../../style/projectComponents'
import { CircleTitle, DocContent } from '../components'
import { useTranslation } from 'react-i18next'

export const First = () => {
  const { t, i18n } = useTranslation()

  return (
    <DocContent>
      <FlexColumnStart className={'block'}>
        <p>{t('documents.aml.1.1')}</p>
      </FlexColumnStart>
      <FlexColumnStart className={'block'}>
        <p>{t('documents.aml.1.2')}</p>
        <CircleTitle text={t('documents.aml.1.3')} />
        <CircleTitle text={t('documents.aml.1.4')} />
        <CircleTitle text={t('documents.aml.1.5')} />
        <CircleTitle text={t('documents.aml.1.6')} />
      </FlexColumnStart>
      <FlexColumnStart className={'block'}>
        <span>{t('documents.aml.1.7')}</span>
        <p>{t('documents.aml.1.8')}</p>
      </FlexColumnStart>
    </DocContent>
  )
}
