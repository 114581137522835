import imageCompression from 'browser-image-compression'

export async function filesCompression(imageFile) {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  }

  try {
    const compressedBlob = await imageCompression(imageFile, options)
    const size = compressedBlob.size / 1024 / 1024
    const compressedFile = new File([compressedBlob], imageFile.name, { type: imageFile.type })

    return { compressedBlob, compressedFile, size }
  } catch (error) {
    console.log(error)
    throw error
  }
}
