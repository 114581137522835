import React, { useContext, useEffect } from 'react'
import {
  EditModalWrapper,
  FlexRow,
  Modal,
  ModalHeader,
  StyledButton,
} from '../../../style/projectComponents'
import styled from 'styled-components/macro'
import { Controller, useForm } from 'react-hook-form'
import { ValidationInputComponent } from '../../global/ValidationInputComponent'
import { useMutation } from 'react-query'
import { UpdateDepositWalletsReq } from '../../../service/admin-api/models'
import AdminApi from '../../../service/admin-api/AdminApi'
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore'
import { useTranslation } from 'react-i18next'
import { isWalletGood } from '../../WithdrawComponents/utils'

const ModalWrapper = styled(Modal)`
  background: ${({ theme }) => theme.colors.modalBg};
  justify-content: center;
  flex-direction: column;
`

const StyledForm = styled.form`
  flex-direction: column;
  display: flex;
  align-items: start;
  border-radius: var(--8, 0.5rem);
  padding: 20px;
  margin: 10px;
  gap: 12px;
  @media (max-width: 780px) {
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
  }
`

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;
  @media (max-width: 480px) {
    width: 100%;
  }
`

type IFormType = {
  name: 'newAdress'
  label: string
  rules: any
  type: string
}

const forms: IFormType[] = [
  {
    name: 'newAdress',
    label: 'New address',
    rules: {
      required: true,
    },
    type: 'string',
  },
]

interface EditWalletModalProps {
  openMenu: boolean
  handleMenu: () => void
  walletAddress: string
  walletId: string
  mutate: any
  mutateData: any
  walletsValidationRegex: string
}

const EditWalletModal: React.FC<EditWalletModalProps> = ({
  openMenu,
  handleMenu,
  walletAddress,
  walletId,
  mutate,
  mutateData,
  walletsValidationRegex,
}) => {
  const {
    control,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      newAdress: walletAddress,
    },
  })

  const updateWallet = useMutation((data: UpdateDepositWalletsReq) =>
    AdminApi.updateDepositWallet(data)
  )
  const GlobalModalStorage = useContext(GlobalModalStore)
  const { t } = useTranslation()

  useEffect(() => {
    setValue('newAdress', walletAddress)
  }, [walletAddress])

  const onSubmit = (data: any) => {
    updateWallet.mutate(
      {
        newAdress: data.newAdress,
        id: walletId,
      },
      {
        onSuccess: data => {
          GlobalModalStorage.makeVisible(true, t('common.success'))
          handleMenu()
          mutate(mutateData)
        },
        onError: error => {
          GlobalModalStorage.makeVisible(false, t('common.error'))
        },
      }
    )
  }

  if (openMenu)
    return (
      <ModalWrapper>
        <EditModalWrapper>
          <ModalHeader>
            <h3>Edit Currency</h3>
            <img
              style={{ cursor: 'pointer' }}
              onClick={handleMenu}
              width={14}
              height={14}
              src={'/images/buttons/close.png'}
              alt={'close'}
            />
          </ModalHeader>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            {forms.map((it, idx) => (
              <InputWrapper>
                <Controller
                  name={it.name}
                  control={control}
                  rules={isWalletGood(RegExp(walletsValidationRegex))}
                  render={({ field: { ref, ...field } }) => (
                    <ValidationInputComponent
                      {...field}
                      maxLength={50}
                      isError={!!errors[it.name]}
                      label={it.label}
                      style={{ fontSize: 14 }}
                    />
                  )}
                />
              </InputWrapper>
            ))}
            <StyledButton
              type={'submit'}
              variant="black"
              style={{
                width: '100%',
                padding: 'var(--16, 1rem)',
                maxWidth: '100%',
              }}
              disabled={!isValid || updateWallet.isLoading}
            >
              {updateWallet.isLoading ? 'Loading' : 'Edit'}
            </StyledButton>
          </StyledForm>
        </EditModalWrapper>
      </ModalWrapper>
    )
}

export default EditWalletModal
