import toLocaleNumber from './toLocaleNumber'
import capitalizeFirstLetter from './capitalizeFirstLetter'
import { InvestPoolDto } from '../service/api/generated'

export const getInvestPoolContent = (investPool: InvestPoolDto, titles: string[]) => {
  const apr =
    investPool.term === 'flexible'
      ? `${investPool.flexibleAprFrom}%-${investPool.flexibleAprTo}%`
      : `${investPool.fixedApr}%`
  const investedInFirstCurrency = investPool?.userWallets?.[0]?.balance || 0
  const totalInvestedNumber = investPool?.totalBalances[0]?.balance || 0
  const userLimit = investPool?.userLimit || 0
  const investedByUserInUsd = investPool?.investedByUserInUsd || 0
  const holdPeriod = `${investPool?.holdInDays || 0} days`
  const iPoolContent = [
    {
      title: titles[0],
      value: `${toLocaleNumber(totalInvestedNumber, 0, 6)} / ${toLocaleNumber(
        investPool.totalLimit,
        0,
        0
      )}`,
    },
    {
      title: titles[1],
      value: `${toLocaleNumber(
        investedInFirstCurrency,
        0,
        6
      )} / ${toLocaleNumber(userLimit, 0, 0)}`,
    },
    {
      title: titles[2],
      value: `$ ${toLocaleNumber(investedByUserInUsd, 0, 2)}`,
    },
    {
      title: titles[3],
      value: investPool?.availableCurrencies?.join(', '),
    },
    {
      title: titles[4],
      value: capitalizeFirstLetter(investPool.period),
    },
    {
      title: titles[5],
      value: capitalizeFirstLetter(investPool.term),
    },
    {
      title: 'APR',
      value: apr,
    },
    {
      title: titles[6],
      value: holdPeriod,
    },
  ]
  return iPoolContent
}
