import React, { useContext, useState } from 'react'
import {
  FlexColumn,
  FlexRow,
  Modal,
  StyledButton as StyledButtonDef,
  StyledTextArea as StyledTextAreaDef,
  TabsButton as TabsButtonDef,
} from '../../../style/projectComponents'

import {
  StyledButton as StyledButtonTime,
  TabsButton as TabsButtonTime,
} from '../../../style/projectTimeComponents'
import { StyledTextArea as StyledTextAreaTime } from '../../../style/projectTimeComponents'
import styled from 'styled-components/macro'
import { useMutation } from 'react-query'
import { DepositWalletsReq } from '../../../service/admin-api/models'
import AdminApi from '../../../service/admin-api/AdminApi'
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore'
import { useTranslation } from 'react-i18next'
import { queryClient } from '../../../config/query-client'
import { Network } from '../../../service/network-api/models'
import { trimmedMessage } from '../../../utils/trimmedMessage'
import { PROJECT_NAME } from '../../../config/serverConstants'

const StyledTextArea = PROJECT_NAME === 'Def' ? StyledTextAreaDef : StyledTextAreaTime

const StyledButton = PROJECT_NAME === 'Def' ? StyledButtonDef : StyledButtonTime

const TabsButton = PROJECT_NAME === 'Def' ? TabsButtonDef : TabsButtonTime
const LangWrapper = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.gray050};
  min-width: 540px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 6px 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const LangModalWrapper = styled(Modal)`
  background: ${({ theme }) => theme.colors.modalBg};
  justify-content: center;
  flex-direction: column;
`
const LangHeader = styled(FlexRow)`
  width: 100%;
  justify-content: flex-end;
  margin: 20px;
  img {
    filter: ${({ theme }) => theme.colors.black === '#fff' && 'invert(1)'};
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
  }
`
const StyledForm = styled.form`
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: start;
  border-radius: var(--8, 0.5rem);
  padding: 1rem var(--4, 2rem) 3rem var(--4, 2rem);
  margin: 10px;

  .inps {
    gap: 6px;
    margin-bottom: 0.81rem;

    .checkbox {
      width: 100%;
      justify-content: left;
    }
  }

  @media (max-width: 780px) {
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px;
  }
`

interface AddWalletsModalProps {
  openMenu: boolean
  handleMenu: () => void
  network: Network
  getWalletsByNetwork: (n: Network) => void
}

const AddWalletsModal: React.FC<AddWalletsModalProps> = ({
  openMenu,
  handleMenu,
  network,
  getWalletsByNetwork,
}) => {
  const updateCurrency = useMutation((data: DepositWalletsReq) => AdminApi.addDepositWallets(data))
  const GlobalModalStorage = useContext(GlobalModalStore)
  const { t } = useTranslation()
  const [addresses, setAddresses] = useState('')
  const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    updateCurrency.mutate(
      {
        networkId: network.id,
        addresses: addresses
          .split(',')
          .map(a => a.trim())
          .filter(a => a),
      },
      {
        onSuccess: data => {
          GlobalModalStorage.makeVisible(true, t('common.success'))
          queryClient.invalidateQueries({ queryKey: ['currency_list'] })
          getWalletsByNetwork(network)
          handleMenu()
        },
        onError: error => {
          GlobalModalStorage.makeVisible(
            false,
            // @ts-ignore
            trimmedMessage(error?.response?.data?.message || t('common.error'))
          )
        },
      }
    )
  }

  if (openMenu)
    return (
      <LangModalWrapper>
        <LangWrapper>
          <LangHeader>
            <img
              style={{ cursor: 'pointer' }}
              onClick={handleMenu}
              width={14}
              height={14}
              src={'/images/buttons/close.png'}
              alt={'close'}
            />
          </LangHeader>
          <FlexRow style={{ gap: '32px' }}>
            <h5 style={{ margin: '4px' }}>Add wallets</h5>
            <TabsButton clicked>{network?.name}</TabsButton>
          </FlexRow>
          <StyledForm onSubmit={onSubmit}>
            <StyledTextArea
              value={addresses}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setAddresses(e.target.value)}
            />
            <br />
            <StyledButton
              type={'submit'}
              variant="black"
              style={{
                width: '100%',
                padding: 'var(--16, 1rem)',
                maxWidth: '100%',
              }}
              disabled={updateCurrency.isLoading || !addresses.length}
              noRadius
            >
              {updateCurrency.isLoading ? 'Loading' : 'Add wallets'}
            </StyledButton>
          </StyledForm>
        </LangWrapper>
      </LangModalWrapper>
    )
}

export default AddWalletsModal
