import { IStartInput } from './FirstStep'

export const isFirstNameGood = {
  required: {
    value: true,
    message: 'auth.errors.name.required',
  },
  minLength: 1,
}
export const isLastNameGood = {
  required: {
    value: true,
    message: 'auth.errors.surname.required',
  },
  minLength: 1,
}
export const isMiddleNameGood = {
  required: {
    value: false,
    message: 'auth.errors.middlename.required',
  },
  minLength: 0,
}

export const isBirthdayGood = {
  required: {
    value: true,
    message: 'auth.errors.birthday.required',
  },
  pattern: {
    value: /(\d{2})-(\d{2})-(\d{4})/,
    message: 'auth.errors.birthday.pattern',
  },
  // valueAsDate: true,
}

export const baseInputs: IStartInput[] = [
  {
    name: 'firstName',
    label: 'auth.name',
    type: 'text',
    rules: isFirstNameGood,
  },
  {
    name: 'lastName',
    label: 'auth.surname',
    type: 'text',
    rules: isLastNameGood,
  },
  {
    name: 'middleName',
    label: 'auth.middlename',
    type: 'text',
    rules: isMiddleNameGood,
  },
  {
    name: 'birthDate',
    label: 'auth.birthday',
    type: 'text',
    rules: isBirthdayGood,
  },
]
