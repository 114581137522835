import styled, { useTheme } from 'styled-components/macro'
import { QRCodeCanvas } from 'qrcode.react'
import { Spacer } from '../global/Spacer'
import { AvailableNetworksForDeposit } from '../../service/admin-api/models'
import { useMutation } from 'react-query'
import { GetDepositWalletParams } from '../../service/network-api/models'
import NetworkApi from '../../service/network-api/NetworkApi'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectLabelItem, StyledSelect } from '../global/StyledSelect'
import { FlexColumn, MarketText } from '../../style/projectComponents'

const DepositBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: clamp(320px, 100%, 500px);
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
`

const PayMethodTitle = styled.p`
  font-size: 18px;
  margin-bottom: 6px;
`
const Address = styled.p`
  font-size: 18px;
  font-weight: 600;
  word-break: break-all;
  cursor: pointer;
  vertical-align: center;
  @media (max-width: 768px) {
    height: 40px;
  }
`

type Props = {
  networks: AvailableNetworksForDeposit[]
  title: string
}

export const DepositWindow = ({ networks, title }: Props) => {
  const { t } = useTranslation()

  const [walletLoadingError, setWalletLoadingError] = useState(false)
  const {
    mutate: getDepositWalletMutate,
    data: walletInfo,
    isLoading,
  } = useMutation((data: GetDepositWalletParams) => NetworkApi.getDepositWallet(data), {
    onSuccess: data => console.log(data),
    onError: (err: any) =>
      err?.response?.data?.message === 'No available wallets in the specified network' &&
      setWalletLoadingError(true),
  })
  const handleDeposit = ({ networkId }: { networkId: string }) => {
    getDepositWalletMutate({ networkId })
  }

  const networksForSelect = useMemo(
    () =>
      networks?.map(n => ({
        label: (n.fullName && `${n.name} - ${n.fullName}`) || n.name,
        value: n.id,
      })),
    [networks]
  )

  const [networkOption, setNetworkOption] = useState<SelectLabelItem>(networksForSelect?.[0])

  useEffect(() => {
    networks?.[0]?.id && handleDeposit({ networkId: networks?.[0]?.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const theme = useTheme()

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: theme.colors.inputBackground,
    }),
  }

  const [copyClicked, setCopyClicked] = useState(false)
  const depositAddress = walletInfo?.address || ''

  const copyAddress = () => {
    if (copyClicked === false) {
      setCopyClicked(true)
      navigator.clipboard.writeText(depositAddress)
      setTimeout(() => setCopyClicked(false), 1000)
    }
  }

  return (
    <DepositBlock>
      {networks?.length > 0 && (
        <FlexColumn style={{ maxWidth: 360, width: '100%' }}>
          <StyledSelect
            styles={customStyles}
            options={networksForSelect}
            classNamePrefix={'Select'}
            value={networkOption}
            onChange={e => {
              const event = e as SelectLabelItem
              setNetworkOption({ label: event.label, value: event.value })
              handleDeposit({ networkId: event.value })
            }}
            menuShouldScrollIntoView={false}
          />
        </FlexColumn>
      )}
      <br />
      {walletLoadingError && (
        <>
          <MarketText>Deposit wallet loading error. </MarketText>
          <MarketText>Please try again later or contact the administration.</MarketText>
        </>
      )}
      {!isLoading && networks?.length <= 0 && (
        <>
          <MarketText>No available deposit methods.</MarketText>
        </>
      )}
      {walletInfo && (
        <>
          <PayMethodTitle>{t('deposit.pay_method')}</PayMethodTitle>
          <Address onClick={copyAddress}>
            {!copyClicked ? walletInfo?.address : t('withdraw.copy')}
          </Address>
          <QRCodeCanvas
            value={walletInfo?.address}
            size={160}
            width={160}
            height={160}
            bgColor={'white'}
            includeMargin={false}
            style={{ borderRadius: 8, marginTop: 16 }}
          />
          <Spacer />
        </>
      )}
    </DepositBlock>
  )
}
