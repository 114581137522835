import React, { useContext } from 'react'
import {
  EditModalWrapper,
  FlexRow,
  Modal,
  ModalHeader,
  StyledButton,
} from '../../../style/projectComponents'
import styled from 'styled-components/macro'
import { Controller, useForm } from 'react-hook-form'
import { ValidationInputComponent } from '../../global/ValidationInputComponent'
import { useMutation } from 'react-query'
import { ICurrency } from '../../../service/admin-api/models'
import AdminApi from '../../../service/admin-api/AdminApi'
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore'
import { useTranslation } from 'react-i18next'
import { queryClient } from '../../../config/query-client'

const ModalWrapper = styled(Modal)`
  background: ${({ theme }) => theme.colors.modalBg};
  justify-content: center;
  flex-direction: column;
`

const StyledForm = styled.form`
  flex-direction: column;
  display: flex;
  align-items: start;
  border-radius: var(--8, 0.5rem);
  padding: 20px;
  margin: 10px;
  gap: 12px;
  @media (max-width: 780px) {
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
  }
`

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;
  @media (max-width: 480px) {
    width: 100%;
  }
`

type IFormType = {
  name: 'name' | 'description' | 'coinGeckoId'
  rules: any
  type: string
}

const forms: IFormType[] = [
  {
    name: 'name',
    rules: {
      required: true,
    },
    type: 'string',
  },
  {
    name: 'description',
    rules: {
      required: true,
    },
    type: 'string',
  },
  {
    name: 'coinGeckoId',
    rules: {
      required: true,
    },
    type: 'string',
  },
]

interface EditCurrencyModalProps {
  openMenu: boolean
  handleMenu: () => void
  currencyId: string
  name: string
  description: string
  coinGeckoId: string
}

const EditCurrencyModal: React.FC<EditCurrencyModalProps> = ({
  openMenu,
  handleMenu,
  currencyId,
  name,
  description,
  coinGeckoId,
}) => {
  const {
    register,
    control,
    clearErrors,
    reset,
    resetField,
    setValue,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: name,
      description: description,
      coinGeckoId: coinGeckoId,
      id: currencyId,
    },
  })

  const updateCurrency = useMutation((data: ICurrency) => AdminApi.updateCurrency(data))
  const GlobalModalStorage = useContext(GlobalModalStore)
  const { t, i18n } = useTranslation()

  const onSubmit = (data: any) => {
    updateCurrency.mutate(data, {
      onSuccess: data => {
        GlobalModalStorage.makeVisible(true, t('common.success'))
        queryClient.invalidateQueries({ queryKey: ['currency_list'] })
      },
      onError: error => {
        GlobalModalStorage.makeVisible(false, t('common.error'))
      },
    })
  }

  if (openMenu)
    return (
      <ModalWrapper>
        <EditModalWrapper>
          <ModalHeader>
            <h3>Edit Currency</h3>
            <img
              style={{ cursor: 'pointer' }}
              onClick={handleMenu}
              width={14}
              height={14}
              src={'/images/buttons/close.png'}
              alt={'close'}
            />
          </ModalHeader>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            {forms.map((it, idx) => (
              <InputWrapper>
                <Controller
                  name={it.name}
                  control={control}
                  rules={it.rules}
                  render={({ field: { ref, ...field } }) => (
                    <ValidationInputComponent
                      {...field}
                      maxLength={50}
                      isError={!!errors[it.name]}
                      label={it.name}
                    />
                  )}
                />
              </InputWrapper>
            ))}
            <StyledButton
              type={'submit'}
              variant="black"
              style={{
                width: '100%',
                padding: 'var(--16, 1rem)',
                maxWidth: '100%',
              }}
              disabled={!isDirty || updateCurrency.isLoading}
            >
              {updateCurrency.isLoading ? 'Loading' : 'Edit'}
            </StyledButton>
          </StyledForm>
        </EditModalWrapper>
      </ModalWrapper>
    )
}

export default EditCurrencyModal
