import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { FlexColumn, FlexRowBetween, FlexRow } from '../../style/projectComponents'
import InvestModal from '../global/modal/InvestModal'
import { IInvestPool } from '../../service/investpool-api/models'
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter'
import WithdrawFromInvestModal from '../global/modal/WithdrawFromInvestModal'
import { InvestPoolDto } from '../../service/api/generated'

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding: 14px 0;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
  background: none;
  width: 100%;
  cursor: pointer;
  text-decoration: none;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gray050};
  }
`
const Value = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
  text-align: right;
`
const LastColumn = styled(FlexColumn)`
  width: 140px;
  align-items: end;
  @media (max-width: 768px) {
    display: none;
  }
`
const ShortName = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
`

interface InvestMethodProps {
  iPool?: InvestPoolDto
  id: number
}

export const InvestMethodRow: React.FC<InvestMethodProps> = ({ iPool, id }) => {
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleWithdrawModal, setVisibleWithdrawModal] = useState(false)

  const logo = iPool.logoDownloadLink

  const apr =
    iPool.term === 'flexible'
      ? `${iPool.flexibleAprFrom || 0}%-${iPool.flexibleAprTo || 0}%`
      : `${iPool.fixedApr || 0}%`

  return (
    <>
      <Block onClick={() => setVisibleModal(true)}>
        <FlexRowBetween style={{ alignItems: 'center' }}>
          <FlexRow style={{ width: 150, justifyContent: 'start' }}>
            <img src={logo} alt="" style={{ borderRadius: '100%', objectFit: 'contain' }} />
            <FlexColumn style={{ alignItems: 'start' }}>
              <ShortName>{iPool.name}</ShortName>
            </FlexColumn>
          </FlexRow>
          <FlexColumn style={{ width: 140, alignItems: 'end' }}>
            <Value>{iPool.availableCurrencies?.join(', ')}</Value>
          </FlexColumn>
          <FlexColumn style={{ width: 140, alignItems: 'end' }}>
            <Value>{apr}</Value>
          </FlexColumn>
          <FlexColumn style={{ width: 140, alignItems: 'end' }}>
            <Value>{capitalizeFirstLetter(iPool?.term)}</Value>
          </FlexColumn>
          <LastColumn>
            <Value>{capitalizeFirstLetter(iPool?.period)}</Value>
          </LastColumn>
        </FlexRowBetween>
      </Block>
      {visibleModal && (
        <InvestModal
          setWithdrawOpen={setVisibleWithdrawModal}
          setIsOpen={setVisibleModal}
          investPool={iPool}
        />
      )}
      {visibleWithdrawModal && (
        <WithdrawFromInvestModal
          setIsOpen={setVisibleWithdrawModal}
          setInvestOpen={setVisibleModal}
          investPool={iPool}
        />
      )}
    </>
  )
}
