import React from 'react'
import styled from 'styled-components/macro'
import {
  AppTitleNormal,
  AppTextNormal,
  FlexColumn,
  FlexRowBetween,
  MarketText,
  FlexRow,
  FlexColumnStart,
} from '../../style/projectComponents'
import { MarketRow } from './MarketRow'
import { marketList } from '../../config/marketList'
import { useTranslation } from 'react-i18next'
import { ITicker } from '../../service/bybit-api/models'

const Block = styled(FlexRowBetween)`
  border: none;
  background: none;
  text-align: left;
  width: calc(100% - 40px);
  background: ${({ theme }) => theme.colors.gray050};
  margin-top: 30px;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 10px;
  border-radius: 20px 20px 0 0;
`
const LastColumnTextMini = styled(MarketText)`
  width: 140px;
  text-align: right;
  @media (max-width: 768px) {
    display: none;
  }
`
const EndOfTable = styled(FlexRow)`
  width: 100%;
  background: ${({ theme }) => theme.colors.gray050};
  height: 20px;
  border-radius: 0 0 20px 20px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`
const Table = styled(FlexColumnStart)`
  width: 100%;

  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    max-width: available;
    overflow: auto;
    padding: 0;
    margin: 0;
  }
`
const Content = styled(FlexColumnStart)`
  @media (max-width: 768px) {
    min-width: 100%;
    width: auto;
  }
  width: 100%;
`

interface MarketComponentProps {
  tickerList?: ITicker[] | null
}

export const MarketComponent: React.FC<MarketComponentProps> = ({ tickerList }) => {
  const { t } = useTranslation()

  return (
    <FlexColumn style={{ width: '100%', padding: '10px 0 0 7px' }}>
      <FlexColumn style={{ width: '100%', alignItems: 'start' }}>
        <AppTitleNormal style={{ marginBottom: 10 }}>{t('market.title')}</AppTitleNormal>
        <AppTextNormal style={{ fontSize: 14, textAlign: 'left' }}>
          {t('market.description')}
        </AppTextNormal>
      </FlexColumn>

      <Table>
        <Content>
          <Block>
            <MarketText style={{ width: 150 }}>{t('market.crypto')}</MarketText>
            <MarketText style={{ width: 140, textAlign: 'right' }}>{t('market.price')}</MarketText>
            <MarketText style={{ width: 140, textAlign: 'right' }}>{t('market.h24')}</MarketText>
            <MarketText style={{ width: 140, textAlign: 'right' }}>{t('market.high24')}</MarketText>
            <MarketText style={{ width: 140, textAlign: 'right' }}>{t('market.low24')}</MarketText>
            <LastColumnTextMini>{t('market.turnover24h')}</LastColumnTextMini>{' '}
          </Block>
          {tickerList &&
            marketList.map((token, id) => (
              <MarketRow
                tickerList={tickerList}
                title={token.symbol}
                pair={token.pair}
                link={token.link}
                id={id}
                key={id}
              />
            ))}
        </Content>
      </Table>

      {/* <EndOfTable /> */}
    </FlexColumn>
  )
}
