import React from 'react'
import { HalfContent } from '../../../style/projectComponents'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { PROJECT_NAME } from '../../../config/serverConstants'

const LeftContent = styled(HalfContent)`
  color: #fff;
  background: ${PROJECT_NAME === 'Def' ? '#000' : '#1A1B1E'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 70px;
  height: auto;

  h2 {
    font-size: 26px;
  }

  p {
    font-size: 16px;
    max-width: 520px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-bottom: 45px;
`
const ImagesContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ImagePlanet = styled.img`
  position: absolute;
  object-fit: contain;
`
const ImagePromo = styled.img`
  position: relative;
  top: -90px;
  left: -5px;
  margin-top: 32px;
  max-height: 400px;
`
const LeftContentComponent: React.FC = () => {
  const { t } = useTranslation()

  return (
    <LeftContent>
      <TextContainer>
        <h2>
          {PROJECT_NAME === 'Time' ? t('auth.presentation.title') : t('auth.presentation.defTitle')}
        </h2>
        <p>
          {PROJECT_NAME === 'Time'
            ? t('auth.presentation.description')
            : t('auth.presentation.defDescription')}
        </p>{' '}
      </TextContainer>
      <ImagesContainer>
        {PROJECT_NAME === 'Time' && (
          <ImagePlanet src="/images/Time/planet.png" alt="planet" width={'700px'} />
        )}
        <ImagePromo src="/images/promo/cmc-app.png" alt="promo" />
      </ImagesContainer>
    </LeftContent>
  )
}

export default LeftContentComponent
