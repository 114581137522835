import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { PageLoader } from './PageLoader'
import { FlexColumn } from '../../style/projectComponents'

const InfoText = styled.p`
  margin-top: 24px;
  font-size: 22px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

const LoaderWrapper = styled(FlexColumn)`
  margin-top: 50px;
  margin-bottom: 50px;
`

export const DataEmptyComponent: React.FC<{
  text?: string
  isLoading?: boolean
  isChart?: boolean
  isEmail?: boolean
}> = ({ text, isLoading, isChart, isEmail }) => {
  const { t } = useTranslation()
  const imageLink =
    (isChart && '/images/interface/chart-empty.svg') ||
    (isEmail && '/images/interface/email.svg') ||
    '/images/interface/data-empty.svg'

  return (
    <>
      {isLoading ? (
        <LoaderWrapper>
          <PageLoader />
        </LoaderWrapper>
      ) : (
        <>
          <img src={imageLink} alt="" style={{ marginTop: 40, width: 180 }} />
          <InfoText>{t(text || 'dashboard.empty_data')}</InfoText>
        </>
      )}
    </>
  )
}
