import { ErrorText, FlexColumn, FlexRow, StyledButton } from '../../../style/projectComponents'
import React, { ComponentPropsWithoutRef, FC, useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import { ValidationInputComponent } from '../../global/ValidationInputComponent'
import { useMutation } from 'react-query'
import AdminApi from '../../../service/admin-api/AdminApi'
import { ICreateCurrencyReq } from '../../../service/admin-api/models'
import { useTranslation } from 'react-i18next'
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore'

type IFormType = {
  name: 'name' | 'description' | 'coinGeckoId'
  rules: any
  type: string
}

const forms: IFormType[] = [
  {
    name: 'name',
    rules: {
      required: true,
    },
    type: 'string',
  },
  {
    name: 'description',
    rules: {
      required: true,
    },
    type: 'string',
  },
  {
    name: 'coinGeckoId',
    rules: {
      required: true,
    },
    type: 'string',
  },
]

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;
  @media (max-width: 480px) {
    width: 100%;
  }
`

const StyledForm = styled.form`
  flex-direction: column;
  display: flex;
  align-items: start;
  background: ${({ theme }) => theme.colors.gray050};
  border-radius: var(--8, 0.5rem);
  padding: 20px 25px;
  margin: 10px;

  .inps {
    gap: 0.81rem;
    margin-bottom: 0.81rem;
    width: 100%;
    .checkbox {
      width: 100%;
      justify-content: left;
    }
  }

  @media (max-width: 780px) {
    align-items: center;
    width: calc(100% - 50px);
    box-sizing: border-box;
    padding: 20px;
  }
`

export const CreateCurrencyForm: FC<ComponentPropsWithoutRef<'form'>> = props => {
  const {
    register,
    control,
    clearErrors,
    reset,
    resetField,
    setValue,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
  } = useForm()

  const createCurrency = useMutation((data: ICreateCurrencyReq) => AdminApi.createCurrency(data))
  const GlobalModalStorage = useContext(GlobalModalStore)

  const onSubmit = (data: any) => {
    createCurrency.mutate(data, {
      onSuccess: data => {
        GlobalModalStorage.makeVisible(true, t('common.success'))
        reset({
          name: '',
          description: '',
        })
      },
      onError: error => {
        GlobalModalStorage.makeVisible(false, t('common.error'))
      },
    })
  }

  const { t } = useTranslation()

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <h3 style={{ marginBottom: '1rem', fontSize: '1.5rem', fontWeight: 400 }}>Create Currency</h3>
      <FlexColumn className={'inps'}>
        {forms.map((it, idx) => (
          <InputWrapper key={idx}>
            <Controller
              name={it.name}
              control={control}
              rules={it.rules}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  maxLength={50}
                  isError={!!errors[it.name]}
                  label={it.name}
                />
              )}
            />
          </InputWrapper>
        ))}
      </FlexColumn>

      {!!errors['name'] && (
        <ErrorText style={{ marginBottom: 10 }}>{t(errors['name']?.message.toString())}</ErrorText>
      )}

      <StyledButton
        type={'submit'}
        variant="black"
        style={{ width: '100%', padding: 'var(--16, 1rem)', maxWidth: 380 }}
        disabled={!isDirty || createCurrency.isLoading}
      >
        {createCurrency.isLoading ? 'Loading' : 'Create'}
      </StyledButton>
    </StyledForm>
  )
}
