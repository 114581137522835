import React from 'react'
import styled from 'styled-components/macro'
import { FlexColumn, FlexRow, FlexRowBetween } from '../../style/projectComponents'
import getColorByValue from '../../utils/getColorByValue'
import { Link } from 'react-router-dom'
import { getActiveMarketPair, getMarketDataByPair } from '../../utils/getActiveMarketPair'
import { ITicker } from '../../service/bybit-api/models'

const Block = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding: 8px 0;
  background: none;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
  background: ${({ theme }) => theme.colors.gray050};

  &:hover {
    background: ${({ theme }) => theme.colors.gray050};
  }
`
const ShortName = styled.p`
  font-size: 12px !important;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
`
const Value = styled.p`
  font-size: 12px !important;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
  text-align: right;
  width: 100%;
`

const CurrencyImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 100%;
  filter: ${({ theme }) => theme.colors.black === '#fff' && 'invert(1)'};
`

interface TradingPairProps {
  link: string
  activePair: string
  tickerList: ITicker[] | null
  addPairToFav: any
  favTradingPairs: string[]
}

export const TradingPair: React.FC<TradingPairProps> = ({
  link,
  activePair,
  tickerList,
  addPairToFav,
  favTradingPairs,
}) => {
  const pairName = link.toUpperCase().split('/')[2] || ''
  const tokenPriceData = getMarketDataByPair(tickerList, pairName)
  const tokenPrice = (tokenPriceData?.lastPrice && tokenPriceData?.lastPrice) || 0
  const priceChangePercent = tokenPriceData?.price24hPcnt * 100 || 0
  const priceChangeString = `${
    Number(priceChangePercent) >= 0 ? '+' : ''
  }${Number(priceChangePercent).toFixed(2)}`
  const pairData = getActiveMarketPair(pairName)
  const pairNameString = pairData?.pair || ''
  const pairSymbol = pairData?.symbol || ''
  const icon = `/images/coins/${pairSymbol.toLowerCase()}.${pairSymbol === 'UNI' ? 'jpg' : 'png'}`

  const isPairFav = favTradingPairs.includes(pairNameString)
  const favIcon = isPairFav
    ? '/images/interface/stars/star-active.png'
    : '/images/interface/stars/star-gray.png'

  return (
    <Block
      to={link}
      style={{ background: activePair === pairName && `${({ theme }) => theme.colors.gray050}` }}
    >
      <FlexRowBetween style={{ alignItems: 'center' }}>
        <FlexRow style={{ justifyContent: 'start', width: 100, marginLeft: 15 }}>
          <FlexRow>
            <CurrencyImage
              src={favIcon}
              alt=""
              style={{ opacity: isPairFav ? 1 : 0.25 }}
              onClick={e => addPairToFav(e, pairNameString)}
            />
            <CurrencyImage src={icon} alt="" />
            <ShortName>{pairNameString}</ShortName>
          </FlexRow>
        </FlexRow>
        <FlexColumn style={{ alignItems: 'end', width: 100 }}>
          <Value style={{ textAlign: 'right' }}>{tokenPrice}</Value>
        </FlexColumn>
        <FlexColumn style={{ alignItems: 'end', width: 60, marginRight: 15 }}>
          <Value
            style={{
              color: getColorByValue(priceChangePercent),
              textAlign: 'right',
            }}
          >
            {priceChangeString} %
          </Value>
        </FlexColumn>
      </FlexRowBetween>
    </Block>
  )
}
