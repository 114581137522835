import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  FlexRowBetween,
  FlexRowResponsive,
} from '../../../style/projectComponents'
import styled from 'styled-components/macro'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BalancesStatisticDto } from '../../../service/api/generated'

const Wrapper = styled(FlexColumn)`
  width: 100%;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  gap: 2.81rem;
`
const Content = styled(FlexRowResponsive)`
  width: 100%;
  justify-content: space-between;
  gap: 5.04rem;

  @media (max-width: 768px) {
    gap: 0.87rem;
    align-items: start;
  }
`

const BalanceColumn = styled(FlexColumnStart)`
  gap: 0.87rem;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const BalanceItem = styled(FlexRowBetween)`
  width: 100%;

  p,
  span {
    font-size: 1rem;
    font-weight: 400;
  }

  span {
    color: #666;
  }
`
type ProgressPercents = {
  balancesZero: number
  balancesFrom0To1000: number
  balancesFrom1000To5000: number
  balancesFrom5000To10000: number
  balancesFrom10000To20000: number
  balancesFrom20000To50000: number
  balancesOverThan50000: number
}

const ProgressColumn = styled(FlexColumn)`
  width: 100%;
  gap: 0.56rem;

  .progress_line {
    border-radius: 100px;
    width: 100%;
    height: 0.4375rem;
    overflow: hidden;
  }
`

const ProgressTitles = styled(FlexRowBetween)`
  width: 100%;

  p {
    color: #666;
    font-size: 0.875rem;
  }
`
const ProgressLine = styled.div`
  width: 100%;
  background: #eef7ff;
  height: 20px;
`
const ColorBlock = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  margin-right: 8px;
`

type IAdminDepositsProps = {
  data: BalancesStatisticDto[]
}

export const AdminDepositsComponent: FC<IAdminDepositsProps> = props => {
  const { data } = props

  const colsFirst = [
    'balancesZero',
    'balancesFrom0To1000',
    'balancesFrom1000To5000',
    'balancesFrom5000To10000',
  ]
  const colsSecond = [
    'balancesFrom10000To20000',
    'balancesFrom20000To50000',
    'balancesOverThan50000',
  ]
  const colors = ['#EEF7FF', '#B8DDFD', '#A4BEE9', '#6893DB', '#246CE7', '#004CC9', '#002B72']

  const filteredData = data.find(it => it.currency === null)
  const balancesCount = colsFirst
    .concat(colsSecond)
    .reduce((it, acc) => (it += filteredData[acc]), 0)

  const returnPercents = data => {
    let res = []
    colsFirst
      .concat(colsSecond)
      .forEach((it, idx) => (res[idx] = Math.ceil((filteredData[it] / balancesCount) * 100)))
    return res
  }

  const { t } = useTranslation()

  return (
    <Wrapper>
      <Content>
        <BalanceColumn>
          {colsFirst.map((it, idx) => (
            <BalanceItem key={idx}>
              <FlexRow>
                <ColorBlock style={{ background: colors[idx] }} />
                <p>{t(`adminUserDetail.balances.${it}`)}</p>
              </FlexRow>
              <span>
                {filteredData[it]}({Math.ceil((filteredData[it] / balancesCount) * 100)}%)
              </span>
            </BalanceItem>
          ))}
        </BalanceColumn>

        <BalanceColumn>
          {colsSecond.map((it, idx) => (
            <BalanceItem key={idx}>
              <FlexRow>
                <ColorBlock style={{ background: colors[idx + 4] }} />
                <p>{t(`adminUserDetail.balances.${it}`)}</p>
              </FlexRow>
              <span>
                {filteredData[it]}({Math.ceil((filteredData[it] / balancesCount) * 100)}%)
              </span>
            </BalanceItem>
          ))}
        </BalanceColumn>
      </Content>
      <ProgressColumn>
        <FlexRow style={{ width: '100%' }}>
          {filteredData &&
            returnPercents(filteredData).map((item, idx) => (
              <ProgressLine style={{ width: `${item}%`, background: colors[idx] }} key={idx} />
            ))}
        </FlexRow>
        <ProgressTitles>
          <p>0</p>
          <p>50 000+</p>
        </ProgressTitles>
      </ProgressColumn>
    </Wrapper>
  )
}
