import React from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContent, AuthModal, AuthTitle, FlexRow, Page } from '../style/projectComponents'
import styled from 'styled-components/macro'
import Content from '../components/auth/RecoveryUpdate/Content'

const StyledPage = styled(Page)`
  background-position: center;
  background-size: cover;
  justify-content: start;

  .header {
    position: absolute;
  }
`

export const ResetPassword = () => {
  const { t } = useTranslation()

  return (
    <StyledPage style={{ background: 'url(/images/bg.svg)' }}>
      <AuthContent>
        <AuthModal>
          <FlexRow
            style={{
              marginBottom: 20,
              position: 'relative',
              alignItems: 'center',
              width: '100%',
              height: 'fit-content',
            }}
          >
            <AuthTitle>{t('forgotPassword.title')}</AuthTitle>
          </FlexRow>
          <Content />
        </AuthModal>
      </AuthContent>
    </StyledPage>
  )
}
