import React, { useState } from 'react'
import styled from 'styled-components/macro'
import {
  AppTitleNormal,
  FlexColumn,
  FlexRow,
  MarketTextMini,
  TradeLabel,
} from '../../style/projectComponents'
import CurrencyModal from '../global/modal/CurrencyModal'

const Block = styled(FlexColumn)`
  width: 240px;
  border-radius: 12px;
  align-items: start;
  color: ${({ theme }) => theme.colors.black};

  h1 {
    font-size: 22px;
    margin: 0;
    padding-bottom: 11px;
    width: 100%;
    text-align: center;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    margin: 0;
    width: 100%;
  }

  @media (max-width: 1100px) {
    h1 {
      font-size: 1.125rem;
    }

    p {
      font-size: 0.75rem;
    }
  }

  @media (max-width: 780px) {
    width: 12.5rem;
  }

  @media (max-width: 480px) {
    width: 100%;
    h1 {
      font-size: 1.25rem;
    }

    p {
      font-size: 0.875rem;
    }
  }
`

const SelectorButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 14px;
    filter: ${({ theme }) => theme.colors.black !== '#fff' && 'invert(1)'};
    transform: rotate(180deg);
  }
`

interface TotalBalanceComponentProps {
  title: string
  description?: string
  symbol?: string
  currenciesRates: any
  activeCurrencyId: number
  setActiveCurrencyId: any
}

export const TotalBalanceComponent: React.FC<TotalBalanceComponentProps> = ({
  title,
  description,
  symbol,
  currenciesRates,
  activeCurrencyId,
  setActiveCurrencyId,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const handleModal = () => {
    setOpenModal(bool => !bool)
  }

  return (
    <Block>
      <CurrencyModal
        openMenu={openModal}
        handleMenu={handleModal}
        activeCurrencyId={activeCurrencyId}
        setActiveCurrencyId={setActiveCurrencyId}
      />
      {description && (
        <TradeLabel style={{ marginBottom: 8, textAlign: 'left' }}>{description}</TradeLabel>
      )}
      <FlexRow style={{ alignItems: 'end' }}>
        <AppTitleNormal style={{ marginRight: 6, fontSize: 22 }}>{title}</AppTitleNormal>
        <FlexRow>
          <MarketTextMini style={{ marginBottom: 2 }}>{symbol}</MarketTextMini>
          {currenciesRates && (
            <SelectorButton onClick={handleModal}>
              <img src="/images/interface/arrow-down.svg" alt="" />
            </SelectorButton>
          )}
        </FlexRow>
      </FlexRow>
    </Block>
  )
}
