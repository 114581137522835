import { apiClient } from '../../config/serverConstants'
import {
  AllDepositWalletsReq,
  AllDepositWalletsRes,
  ChangeExchangeCommissionReq,
  DepositWalletsReq,
  GetExchangeCommissionsStatisticReq,
  GetExchangeCommissionsStatisticRes,
  GetFullStatisticAdmin,
  IAccrualOfInterestReq,
  IAdminGetRolesResponse,
  IAdminInterface,
  ICreateCurrencyReq,
  ICurrency,
  IDepositMoneyRequest,
  IGetAllOperationsListAdminReq,
  IGetAllOperationsListAdminResp,
  IGetCurrenciesReq,
  IGetCurrenciesRes,
  IUpdateUserRolesRequest,
  RemoveDepositWalletsReq,
  UpdateDepositWalletsReq,
} from './models'

class AdminServices implements IAdminInterface {
  async depositMoney(data: IDepositMoneyRequest) {
    const response = await apiClient.post('/api/Admin/depositMoney', data)
    return response.data as string
  }

  async getAllRoles() {
    const response = await apiClient.get('/api/Admin/getAllRoles')
    return response.data as IAdminGetRolesResponse
  }

  async updateUserRoles(data: IUpdateUserRolesRequest) {
    const response = await apiClient.post('/api/Admin/updateUserRoles', data)
    return response.data as string
  }

  async accrualOfInterest(data: IAccrualOfInterestReq) {
    const response = await apiClient.post('/api/Admin/accrualOfInterest', data)
    return response.data as string
  }

  async getAllOperationsList(data: IGetAllOperationsListAdminReq) {
    const response = await apiClient.post('/api/Admin/getAllOperationsList', data)
    return response.data as IGetAllOperationsListAdminResp
  }

  async getFullStatistic() {
    const response = await apiClient.get('/api/Admin/getFullStatistic')
    return response.data as GetFullStatisticAdmin
  }

  async getCurrencies(data: IGetCurrenciesReq): Promise<IGetCurrenciesRes> {
    const response = await apiClient.get('/api/Currencies/getCurrencies', {
      params: data,
    })
    return response.data
  }

  async createCurrency(data: ICreateCurrencyReq) {
    const response = await apiClient.post('/api/Currencies/createCurrency', data)
    return response.data as any
  }

  async updateCurrency(data: ICurrency) {
    const response = await apiClient.post('/api/Currencies/updateCurrency', data)
    return response.data as any
  }

  async addDepositWallets(data: DepositWalletsReq) {
    const response = await apiClient.post('/api/Admin/addDepositWallets', data)
    return response.data
  }

  async removeDepositWallet(data: RemoveDepositWalletsReq) {
    const response = await apiClient.post('/api/Admin/removeDepositWallet', data)
    return response.data
  }

  async updateDepositWallet(data: UpdateDepositWalletsReq) {
    const response = await apiClient.post('/api/Admin/updateDepositWallet', data)
    return response.data
  }

  async getExchangeCommissionsStatistic(data: GetExchangeCommissionsStatisticReq) {
    const response = await apiClient.post('/api/Admin/getExchangeCommissionsStatistic', data)
    return response.data as GetExchangeCommissionsStatisticRes
  }

  async changeExchangeCommission(data: ChangeExchangeCommissionReq) {
    const response = await apiClient.post('/api/Admin/changeExchangeCommission', data)
    return response.data as boolean
  }

  async getAllDepositWallets(data: AllDepositWalletsReq): Promise<AllDepositWalletsRes> {
    const response = await apiClient.post('/api/Admin/getAllDepositWallets', data)
    return response.data
  }
}
const AdminService = new AdminServices()
export default AdminService
