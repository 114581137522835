import { Controller, FieldValues, useForm } from 'react-hook-form'
import { ValidationInputComponent } from '../../global/ValidationInputComponent'
import {
  AuthInputWrapper,
  ErrorText,
  InputAndErrorWrapper,
  InputTitle,
  StyledRegisterButton as StyledRegisterButtonDef,
} from '../../../style/projectComponents'
import { StyledRegisterButton as StyledRegisterButtonTime } from '../../../style/projectTimeComponents'
import React, { FC, useContext, useState } from 'react'
import { RegisterContext, STAGE } from '../../../pages/RegisterPage'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { baseInputs } from './FirstUtils'
import { DatePicker } from '../../global/DatePicker'
import { PROJECT_NAME } from '../../../config/serverConstants'

const StyledRegisterButton =
  PROJECT_NAME === 'Def' ? StyledRegisterButtonDef : StyledRegisterButtonTime

export type IFirstStepProps = {
  setRegStage: (e: STAGE) => void
}

type IFormValues = {
  firstName: string
  lastName: string
  middleName: string
  birthDate: string
}

export type IStartInput = {
  name: 'firstName' | 'lastName' | 'middleName' | 'birthDate'
  label: string
  type: string
  rules: FieldValues
}

const FirstStep: FC<IFirstStepProps> = props => {
  const { setRegStage } = props

  const { formUser, setFormUser } = useContext(RegisterContext)

  const {
    control,
    setValue,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues: {
      firstName: formUser.firstName,
      lastName: formUser.lastName,
      middleName: formUser.middleName,
      birthDate: formUser.birthDate,
    },
  })

  const onSubmit = data => {
    setFormUser({ ...formUser, ...data })
    setRegStage(STAGE.FINAL)
  }

  const [openBirthdate, setOpenBirthdate] = useState(false)

  const { t, i18n } = useTranslation()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {openBirthdate && (
        <DatePicker
          open={openBirthdate}
          setOpen={setOpenBirthdate}
          date={watch('birthDate')}
          setDate={setValue}
        />
      )}

      {baseInputs.map((it, idx) => (
        <InputAndErrorWrapper key={idx}>
          <InputTitle style={{ width: '100%', textAlign: 'left' }}>{t(it.label)}</InputTitle>
          <AuthInputWrapper style={{ marginBottom: 5, marginTop: idx !== 0 && 10 }}>
            <Controller
              name={it.name}
              control={control}
              rules={it.rules}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  type={it.type}
                  onBlur={() => it.name === 'birthDate' && setOpenBirthdate(prev => !prev)}
                  onClick={() => it.name === 'birthDate' && setOpenBirthdate(prev => !prev)}
                  isError={!!errors[it.name]}
                  label={t(it.label)}
                />
              )}
            />
          </AuthInputWrapper>
          <ErrorText hasError={!!errors[it.name]} style={{ maxWidth: 300 }}>
            {t(errors[it.name]?.message?.toString())}
          </ErrorText>
        </InputAndErrorWrapper>
      ))}
      <StyledRegisterButton type={'submit'}>{t('auth.continue')}</StyledRegisterButton>
    </form>
  )
}

export default observer(FirstStep)
