import { AuthContent, AuthModal, AuthTitle, FlexRow, Page } from '../style/projectComponents'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FirstStep from '../components/auth/Recover/FirstStep'
import SecondStep from '../components/auth/Recover/SecondStep'
import styled from 'styled-components/macro'

export enum REC_STAGE {
  BASE = 'BASE', //email input
  FINAL = 'FINAL', //success
}

const StyledPage = styled(Page)`
  background-position: center;
  background-size: cover;
  justify-content: start;

  .header {
    position: absolute;
  }
`
export const ForgotPassword: FC = () => {
  const [stage, setStage] = useState(REC_STAGE.BASE)

  const { t } = useTranslation()

  return (
    <StyledPage style={{ background: 'url(/images/bg.svg)' }}>
      <AuthContent>
        <AuthModal>
          <FlexRow
            style={{
              marginBottom: 20,
              position: 'relative',
              alignItems: 'center',
              width: '100%',
              height: 'fit-content',
            }}
          >
            <AuthTitle>{t('forgotPassword.title')}</AuthTitle>
          </FlexRow>

          {stage === REC_STAGE.BASE ? <FirstStep setRecStage={setStage} /> : <SecondStep />}
        </AuthModal>
      </AuthContent>
    </StyledPage>
  )
}
