import React from 'react'
import styled from 'styled-components/macro'
import { FlexRow } from '../../style/projectComponents'

const Block = styled(FlexRow)`
  justify-content: space-between;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  padding: 10px;
  user-select: none;
  @media (max-width: 480px) {
    width: 100%;
  }
`

interface WalletWithBalancesProps {
  id: number
  content: string
  removeTag: (id: number) => void
}

const RemovableTag: React.FC<WalletWithBalancesProps> = ({ id, content, removeTag }) => {
  return (
    <Block onClick={() => removeTag(id)}>
      <p>{content}</p>
    </Block>
  )
}

export default RemovableTag
