import React from 'react'
import styled from 'styled-components/macro'
import { FlexColumn } from '../../style/projectComponents'
import { ITicker } from '../../service/bybit-api/models'
import { TabType } from '../../pages/MarketsPage/MarketsPage'
import { TradingPairsComponent } from './TradingPairsComponent'

const Block = styled(FlexColumn)<{ $tab: TabType }>`
  width: 25vw;
  justify-content: start;
  max-width: 300px;
  height: 970px;
  background: ${({ theme }) => theme.colors.gray050};

  @media (max-width: 600px) {
    height: 100%;
    display: ${props => (props.$tab === 'trading' ? 'block' : 'none')};
    width: 100vw;
    max-width: 100vw;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`

interface TradingPairsListProps {
  tradingPair: string
  tickerList: ITicker[] | null
  tab: TabType
}

export const TradingPairsList: React.FC<TradingPairsListProps> = ({
  tradingPair,
  tickerList,
  tab,
}) => {
  return (
    <Block $tab={tab}>
      <TradingPairsComponent tradingPair={tradingPair} tickerList={tickerList} />
    </Block>
  )
}
