import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { Content, CustomStyledLink, DocWrapper, StyledHr, Title, Wrapper } from '../components'
import { FlexColumnStart } from '../../../style/projectComponents'
import { First } from './First'
import { Second } from './Second'

export const TermsPage = () => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Wrapper>
      <Content>
        <CustomStyledLink to={'/'}>
          <img src={'/images/arrBack.svg'} /> {t('documents.back')}
        </CustomStyledLink>

        <FlexColumnStart className={'docs'}>
          <Title>{t('footer.termsOfUse')}</Title>

          <DocWrapper>
            <First />
            <StyledHr />
            <Second />
          </DocWrapper>
        </FlexColumnStart>
      </Content>
    </Wrapper>
  )
}
