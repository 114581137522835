import React from 'react'
import styled from 'styled-components/macro'
import { FlexColumn, FlexRow, FlexRowBetween } from '../../style/projectComponents'
import toLocaleNumber from '../../utils/toLocaleNumber'
import getColorByValue from '../../utils/getColorByValue'
import { Link } from 'react-router-dom'
import { ITicker } from '../../service/bybit-api/models'
import { getMarketDataByPair } from '../../utils/getActiveMarketPair'
import { formatNumber } from '../../utils/formatNumber'

const Block = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
  background: none;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 20px;
  width: calc(100% - 40px);
  background: ${({ theme }) => theme.colors.gray050};
  &:last-child {
    border-end-end-radius: 20px;
    border-end-start-radius: 20px;
  }

  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-right: 10px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gray050};
  }
`
const ShortName = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
`
const FullName = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.dark};
  text-align: left;
`
const Value = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
  text-align: right;
`
const LastColumn = styled(FlexColumn)`
  width: 140px;
  align-items: end;
  @media (max-width: 768px) {
    display: none;
  }
`

interface MarketRowProps {
  title: string
  pair: string
  id: number
  link: string
  tickerList: ITicker[] | null
}

export const MarketRow: React.FC<MarketRowProps> = ({ title, link, pair, id, tickerList }) => {
  const pairString = pair.replace('/', '')
  const fullName = title
  const icon = `/images/coins/${title.toLowerCase()}.${title === 'UNI' ? 'jpg' : 'png'}`

  const tokenPriceData = getMarketDataByPair(tickerList, pairString)
  const tokenPrice = (tokenPriceData?.lastPrice && toLocaleNumber(tokenPriceData?.lastPrice)) || 0
  const priceLow24h = tokenPriceData?.lowPrice24h || 0
  const priceHigh24h = tokenPriceData?.highPrice24h || 0
  const priceChangePercent = tokenPriceData?.price24hPcnt * 100 || 0
  const priceChangeString = `${
    Number(priceChangePercent) >= 0 ? '+' : ''
  }${Number(priceChangePercent).toFixed(2)}`
  const turnover24h = tokenPriceData?.turnover24h || 0

  return (
    <Block to={link}>
      <FlexRowBetween style={{ alignItems: 'center' }}>
        <FlexRow style={{ width: 150, justifyContent: 'start' }}>
          <img src={icon} alt="" style={{ borderRadius: '100%' }} />
          <FlexColumn style={{ alignItems: 'start' }}>
            <ShortName>{title}</ShortName>
            <FullName>{fullName}</FullName>
          </FlexColumn>
        </FlexRow>
        <FlexColumn style={{ width: 140, alignItems: 'end' }}>
          <Value>{toLocaleNumber(Number(tokenPrice))}</Value>
        </FlexColumn>
        <FlexColumn style={{ width: 140, alignItems: 'end' }}>
          <Value style={{ color: getColorByValue(priceChangePercent) }}>
            {priceChangeString} %
          </Value>
        </FlexColumn>
        <FlexColumn style={{ width: 140, alignItems: 'end' }}>
          <Value>{toLocaleNumber(Number(priceHigh24h))}</Value>
        </FlexColumn>
        <FlexColumn style={{ width: 140, alignItems: 'end' }}>
          <Value>{toLocaleNumber(Number(priceLow24h))}</Value>
        </FlexColumn>
        <LastColumn>
          <Value>{formatNumber(turnover24h)}</Value>
        </LastColumn>
      </FlexRowBetween>
    </Block>
  )
}
