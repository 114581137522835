import { action, autorun, computed, makeAutoObservable } from 'mobx'
import { createContext } from 'react'
import { ITokens } from '../../service/auth-api/models'

const getLocalStorageItem = (item: string) => {
  try {
    if (localStorage[item]) return JSON.parse(localStorage[item])
    else return null
  } catch {
    return null
  }
}
class UserStorage {
  user: any = localStorage.user ? JSON.parse(localStorage.user) : null
  accessToken: string = getLocalStorageItem('accessToken')
  refreshToken: string = getLocalStorageItem('refreshToken')
  isAuth: boolean = getLocalStorageItem('isAuth')

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
    autorun(async () => {
      localStorage.user = JSON.stringify(this.user)
      localStorage.accessToken = JSON.stringify(this.accessToken)
      localStorage.refreshToken = JSON.stringify(this.refreshToken)
      localStorage.isAuth = JSON.stringify(this.isAuth)
    })
  }

  @computed isAdmin() {
    const res = this.user ? this.user['UserRole']?.includes('Admin') : false
    return res
  }

  @action setUser(data: any) {
    this.user = data
  }

  @action setIsAuth(status: boolean) {
    this.isAuth = status
  }

  @action logout() {
    sessionStorage['language'] = localStorage['language']
    localStorage.clear()
    localStorage['language'] = sessionStorage['language']
  }

  @action setTokens(response: ITokens) {
    this.accessToken = response.accessToken
    this.refreshToken = response.refreshToken
  }
}

export default createContext(new UserStorage())
