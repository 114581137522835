import React from 'react'
import styled from 'styled-components/macro'
import { FlexColumn } from '../style/projectComponents'

interface TopDownSelectorProps {
  isTopClicked: boolean
  clickOnTop: any
  isBottomClicked: boolean
  clickOnBot: any
}

const SelectorButton = styled.button<{ isActive: boolean }>`
  background: none;
  border: none;
  height: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    filter: ${({ theme, isActive }) =>
      (isActive && theme.colors.black !== '#fff' && 'invert(1)') ||
      (!isActive && theme.colors.black === '#fff' && 'invert(1)') ||
      'none'};
  }
`

export const TopDownSelector: React.FC<TopDownSelectorProps> = ({
  isTopClicked,
  clickOnBot,
  clickOnTop,
  isBottomClicked,
}) => {
  return (
    <FlexColumn style={{ gap: 0 }}>
      <SelectorButton onClick={clickOnTop} isActive={isTopClicked}>
        <img src="/images/interface/arrow-down.svg" alt="" />
      </SelectorButton>
      <SelectorButton onClick={clickOnBot} isActive={isBottomClicked}>
        <img
          src="/images/interface/arrow-down.svg"
          alt=""
          style={{
            transform: 'rotate(180deg)',
          }}
        />
      </SelectorButton>
    </FlexColumn>
  )
}
