import React from 'react'
import { EditModalWrapper, Modal, ModalHeader } from '../../../style/projectComponents'
import styled from 'styled-components/macro'
import { ICurrency } from '../../../service/admin-api/models'
import ConvertCurrencyItem from '../../../pages/ConvertPage/ConvertCurrencyItem'

const SelecCurrencyWrapper = styled(Modal)`
  background: ${({ theme }) => theme.colors.modalBg};
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    justify-content: start;
  }
`

interface LanguageModalProps {
  openMenu: boolean
  handleMenu: () => void
  swapFrom: boolean
  currencies: ICurrency[]
  firstTokenId: number
  secondTokenId: number
  setFirstTokenId: any
  setSecondTokenId: any
}

const SelectCurrencyModal: React.FC<LanguageModalProps> = ({
  openMenu,
  handleMenu,
  swapFrom,
  currencies,
  firstTokenId,
  secondTokenId,
  setFirstTokenId,
  setSecondTokenId,
}) => {
  const preventClick = e => {
    e.stopPropagation()
  }

  const selectedId = swapFrom ? firstTokenId : secondTokenId
  const removedId = swapFrom ? secondTokenId : firstTokenId
  const setTokenId = swapFrom ? setFirstTokenId : setSecondTokenId

  if (openMenu)
    return (
      <SelecCurrencyWrapper onClick={handleMenu}>
        <EditModalWrapper onClick={preventClick}>
          <ModalHeader>
            <h3>{swapFrom ? 'Currency From' : 'Currency To'}</h3>
            <img
              style={{ cursor: 'pointer' }}
              onClick={handleMenu}
              width={14}
              height={14}
              src={'/images/buttons/close.png'}
              alt={'close'}
            />
          </ModalHeader>
          {currencies?.map((c, id) => (
            <ConvertCurrencyItem
              currency={c}
              key={c.id}
              id={id}
              selectedId={selectedId}
              setTokenId={setTokenId}
              handleMenu={handleMenu}
              removedId={removedId}
            />
          ))}
        </EditModalWrapper>
      </SelecCurrencyWrapper>
    )
}

export default SelectCurrencyModal
