import {
  ErrorText,
  FlexColumnStart,
  FlexRow,
  InputTitle,
  PassIcon,
  StyledRegisterButton as StyledRegisterButtonDef,
} from '../../style/projectComponents'
import { StyledRegisterButton as StyledRegisterButtonTime } from '../../style/projectTimeComponents'
import { Controller, useForm } from 'react-hook-form'
import { ValidationInputComponent } from '../global/ValidationInputComponent'
import { useContext, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { settingsInputs } from '../../pages/SettingsPage/inputsModels'
import { useMutation } from 'react-query'
import UserApi from '../../service/user-api/UserApi'
import ProfileStorage from '../../storage/ProfileStorage/ProfileStorage'
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore'
import { PROJECT_NAME } from '../../config/serverConstants'

const StyledRegisterButton =
  PROJECT_NAME === 'Def' ? StyledRegisterButtonDef : StyledRegisterButtonTime

const SettingsBlock = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 25px;
  border-radius: 16px;
  width: 526px;
  background: ${({ theme }) => theme.colors.gray050};
  margin-bottom: 20px;

  img {
    margin-bottom: 25px;
    margin-top: 25px;
    border-radius: 10px;
  }

  @media (max-width: 600px) {
    width: 100%;
    max-width: calc(100% - 70px);
  }
`
const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 100%;
  margin-bottom: 5px;
  @media (max-width: 600px) {
    width: 100%;
  }
`

type IInputs = {
  email: string
  phoneNumber: string
  oldPassword: string
  newPassword: string
  badRequest: any
}
const StyledPassIcon = styled(PassIcon)`
  top: 0;
  transform: translateY(-25%);
`

const StyledAdvise = styled.p`
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  min-height: 14px;
  margin-bottom: 16px;
`
const InputBox = styled(FlexColumnStart)`
  margin-bottom: 5px;
  width: 100%;
  @media (max-width: 600px) {
    width: 100%;
    div {
      label {
        left: 20px;
      }
    }
  }
`
export const SettingsInputs = () => {
  const { t } = useTranslation()
  const ProfileStore = useContext(ProfileStorage)

  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    setValue,
    formState: { isDirty, errors },
  } = useForm<IInputs>({
    defaultValues: {
      email: ProfileStore.profile.email,
      phoneNumber: ProfileStore.profile.phoneNumber,
      oldPassword: '',
      newPassword: '',
    },
  })

  const updatePasswordMutation = useMutation(
    (data: { oldPassword: string; newPassword: string }) =>
      UserApi.updatePassword(data.oldPassword, data.newPassword),
    {
      onError: error => {
        setError('badRequest', {
          type: 'manual',
          message: 'auth.errors.password.serverError',
        })
      },
    }
  )

  const GlobalModalStorage = useContext(GlobalModalStore)

  const updatePhoneMutation = useMutation((phone: string) =>
    UserApi.updateUserInfo({
      ...ProfileStore.profile,
      phoneNumber: phone,
    })
  )

  const onSubmit = data => {
    if (data.oldPassword !== '') {
      updatePasswordMutation.mutate(
        {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        },
        {
          onSuccess: data => {
            GlobalModalStorage.makeVisible(true, t('common.success'))
          },
          onError: error => {
            GlobalModalStorage.makeVisible(false, t('common.error'))
          },
        }
      )
    }
    updatePhoneMutation.mutate(data.phoneNumber, {
      onSuccess: data1 => {
        ProfileStore.setProfile({
          ...ProfileStore.profile,
          phoneNumber: data.phoneNumber,
        })
        GlobalModalStorage.makeVisible(true, t('common.success'))
      },
      onError: error => {
        GlobalModalStorage.makeVisible(false, t('common.error'))
      },
    })
  }

  const inputTitles = [
    t('auth.email'),
    t('settings.inputs.phone.title'),
    t('settings.inputs.password.title'),
  ]

  const [showPass, setShowPass] = useState(false)
  const [showNewPass, setShowNewPass] = useState(false)

  const handleCheckType = (name: string, typeDefault: any) => {
    switch (name) {
      case 'oldPassword':
        return showPass ? 'text' : 'password'
      case 'newPassword':
        return showNewPass ? 'text' : 'password'
      default:
        return typeDefault
    }
  }

  const [advisePassword, setAdvisePassword] = useState('settings.inputs.password.errors.pattern')

  return (
    <SettingsBlock onSubmit={handleSubmit(onSubmit)}>
      {settingsInputs.map((it, idx) => (
        <InputBox key={idx}>
          {idx < 3 && <InputTitle>{inputTitles[idx]}</InputTitle>}
          <InputWrapper>
            <Controller
              name={it.name}
              control={control}
              rules={it.name === 'newPassword' ? it.rules(watch) : it.rules}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  onChange={e => {
                    setValue(it.name, e.target.value.replace('‎\u200e', ''))
                  }}
                  value={it.name === 'phoneNumber' ? '‎\u200e' + field.value : field.value}
                  type={handleCheckType(it.name, it.type)}
                  isError={!!errors[it.name]}
                  label={t(it.label)}
                />
              )}
            />

            {it.name === 'oldPassword' && (
              <StyledPassIcon
                onClick={() => setShowPass(prev => !prev)}
                src={showPass ? '/images/show.svg' : '/images/hide.svg'}
              />
            )}

            {it.name === 'newPassword' && (
              <StyledPassIcon
                onClick={() => setShowNewPass(prev => !prev)}
                src={showNewPass ? '/images/show.svg' : '/images/hide.svg'}
              />
            )}
          </InputWrapper>

          {it.name === 'oldPassword' && !errors[it.name] && (
            <StyledAdvise>{t(advisePassword)}</StyledAdvise>
          )}

          <ErrorText hasError={!!errors[it.name]}>
            {t(errors[it.name]?.message?.toString())}
          </ErrorText>
        </InputBox>
      ))}

      <StyledRegisterButton
        onClick={() => clearErrors()}
        disabled={updatePasswordMutation.isLoading || updatePhoneMutation.isLoading}
        type={'submit'}
        noRadius
        style={{ width: '100%', maxWidth: '100%' }}
      >
        {t('settings.save')}
      </StyledRegisterButton>

      <ErrorText style={{ marginTop: 10 }} hasError={!!errors.badRequest}>
        {t(errors?.badRequest?.message.toString())}
      </ErrorText>
    </SettingsBlock>
  )
}
