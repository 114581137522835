import styled from 'styled-components/macro'
import { FlexRow, FlexRowBetween, StyledLink } from '../../../../style/projectComponents'
import { Link } from 'react-router-dom'

export const Header = styled.div<{ isAuth?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  height: 64px;
  background: ${({ isAuth }) => (isAuth ? '#303134' : '#1a1b1e')};
`

interface ContentProps {
  isLanding: boolean
}

export const Content = styled.div<ContentProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  padding-left: 64px;
  padding-right: 64px;
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

export const Logo = styled.img`
  height: 64px;
  margin: 0 10px;
`

export const RightWrapper = styled(FlexRow)`
  align-items: center;
  margin-right: 20px;
  column-gap: 20px;
  @media (max-width: 600px) {
    width: auto;
    column-gap: 10px;
    margin-right: 0px;
  }
`
export const BtnWrapper = styled(FlexRow)`
  column-gap: 0.5rem;
  @media (max-width: 768px) {
    display: none;
  }
`
export const AppMenu = styled(FlexRowBetween)<{
  menuOpen: boolean
  isAdminPaths: boolean
}>`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  box-shadow: ${({ menuOpen }) => (menuOpen ? '1px 4px 8px 1px rgba(0,0,0,0.25)' : 'none')};
  display: ${props => (props.isAdminPaths ? 'flex' : 'none')};
  @media (max-width: 1200px) {
    display: flex;
  }
`

export const SettingsButton = styled(FlexRow)`
  width: 20px;
  height: 20px;
  cursor: pointer;

  img {
    width: 20px;
  }
`
export const ExitButton = styled(SettingsButton)`
  @media (max-width: 1200px) {
    display: none;
  }
`

export const SettingsLink = styled(Link)`
  display: flex;
  width: 20px;
  height: 20px;
  cursor: pointer;

  img {
    width: 20px;
  }
`

interface HeaderLinkProps {
  $isSelected: boolean
}

export const HeaderLink = styled(StyledLink)<HeaderLinkProps>`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  background: ${({ $isSelected }) => ($isSelected ? '#22B8B6' : 'none')};
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${({ $isSelected }) => ($isSelected ? 'transparent' : '#fff')};

  &:hover {
    background: #22b8b6;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
export const LinksWrapper = styled(FlexRow)`
  gap: 32px;
  @media (max-width: 1200px) {
    display: none;
  }
`

export const ThemeButtonsWrapper = styled(FlexRow)`
  margin-right: 32px;
  @media (max-width: 480px) {
    margin-right: 5px;
  }
`
export const ThemeButton = styled(FlexRow)<{ $isActive: boolean }>`
  width: 20px;
  height: 20px;
  padding: 8px;
  background: ${({ $isActive }) => ($isActive ? '#22B8B6' : 'none')};
  border-radius: 9px;
  cursor: pointer;
  box-sizing: content-box;

  img {
    width: 20px;
    height: 20px;
  }
`
