import { FlexColumnStart } from '../../../style/projectComponents'
import { CircleTitle, DocContent } from '../components'
import { useTranslation } from 'react-i18next'

export const Second = () => {
  const { t, i18n } = useTranslation()

  return (
    <DocContent>
      <FlexColumnStart className={'block'}>
        <span>{t('documents.aml.2.1')}</span>
        <p>{t('documents.aml.2.2')}</p>
      </FlexColumnStart>
      <FlexColumnStart className={'block'}>
        <span>{t('documents.aml.2.3')}</span>
        <p>{t('documents.aml.2.4')}</p>
      </FlexColumnStart>
      <FlexColumnStart className={'block'}>
        <span>{t('documents.aml.2.5')}</span>
        <p>{t('documents.aml.2.6')}</p>
      </FlexColumnStart>
      <FlexColumnStart className={'block'}>
        <span>{t('documents.aml.2.7')}</span>
        <p>{t('documents.aml.2.8')}</p>
      </FlexColumnStart>
      <FlexColumnStart className={'block'}>
        <span>{t('documents.aml.2.9')}</span>
        <p>{t('documents.aml.2.10')}</p>
      </FlexColumnStart>
    </DocContent>
  )
}
