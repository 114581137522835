import {
  IAccrualOfInterestDashboardItemsResponse,
  ICurrentUserResponse,
  IDashboardItemsRequest,
  IDashboardItemsResponse,
  IGetUserOperationsRequest,
  IGetUserOperationsResponse,
  IGetUserReferralsReq,
  IGetUserReferralsRes,
  IGlobalDashboardItemsRequest,
  IGlobalDashboardItemsResponse,
  IUpdateUserInfo,
  IUserDetailsResponse,
  IUserInterface,
  IUserListReq,
  IUserListResponse,
} from './models'
import { apiClient } from '../../config/serverConstants'
import { GetUserOperationsListQuery } from '../api/generated'

class UserService implements IUserInterface {
  async getDashboardItems(data: IDashboardItemsRequest) {
    const response = await apiClient.post('/api/User/getDashboardItems', data)
    return response.data as IDashboardItemsResponse
  }

  async getGlobalDashboardItems(data: IGlobalDashboardItemsRequest) {
    const response = await apiClient.post('/api/User/getGlobalDashboardItems', data)
    return response.data as IGlobalDashboardItemsResponse
  }

  async getAccrualOfInterestDashboardItems(data: IDashboardItemsRequest) {
    const response = await apiClient.post('/api/User/getAccrualOfInterestDashboardItems', data)
    return response.data as IAccrualOfInterestDashboardItemsResponse
  }

  async getUserOperationsList(data: GetUserOperationsListQuery) {
    const response = await apiClient.post('/api/User/getUserOperationsList', data)
    return response.data as IGetUserOperationsResponse
  }

  async getAllUsersList(data: IUserListReq) {
    const response = await apiClient.get('/api/User/getAllUsersList', {
      params: data,
    })
    return response.data as IUserListResponse
  }

  async getCurrentUser() {
    const response = await apiClient.get('/api/User/getCurrentUser')
    return response.data as ICurrentUserResponse
  }

  async getUserDetails(userId: string) {
    const response = await apiClient.post('/api/User/getUserDetails', {
      userId: userId,
    })
    return response.data as IUserDetailsResponse
  }

  async updatePassword(oldPassword: string, newPassword: string) {
    const response = await apiClient.post('/api/User/updatePassword', {
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
    return response.data as string
  }

  async updateUserInfo(data: IUpdateUserInfo) {
    const response = await apiClient.post('/api/User/updateUserInfo', data)
    return response.data as string
  }

  async getUserReferrals(data: IGetUserReferralsReq) {
    const response = await apiClient.get('/api/User/getUserReferrals')
    return response.data as IGetUserReferralsRes
  }
}

export default new UserService()
