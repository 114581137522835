import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import {
  AppTextMini,
  FlexColumn,
  FlexRow,
  FlexRowBetween,
  MarketText,
  MarketTextBlack,
} from '../../style/projectComponents'
import { useTranslation } from 'react-i18next'
import { marketList } from '../../config/marketList'
import { TradingPair } from './TradingPair'
import { ITicker } from '../../service/bybit-api/models'
import { SearchValueInput } from '../global/SearchValueInput'
import { TopDownSelector } from '../TopDownSelector'
import { getActiveMarketPair, getMarketDataByPair } from '../../utils/getActiveMarketPair'

const BlockHeader = styled(FlexColumn)`
  justify-content: start;
  width: 100%;
  padding: 10px 0;
  align-items: start;
`
const TradingPairsHeader = styled(FlexRowBetween)`
  padding: 0 10px;
  align-items: center;
  margin-bottom: 10px;
  padding-top: 5px;
`

const SearchWrapper = styled(FlexRowBetween)`
  width: calc(100% - 30px);
  border-radius: 12px;
  margin-bottom: 16px;
`
const TradingPairsBlock = styled(FlexColumn)`
  overflow: scroll;
  width: 100%;
  justify-content: start;
`
const TradingPairsCategories = styled(FlexRowBetween)`
  width: calc(100% - 10px);
  padding: 0 10px;
  padding-top: 5px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
  justify-content: start;
  gap: 24px;
`
const SelectCategoryButton = styled(MarketText)<{ isSelected: boolean }>`
  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.black : theme.colors.grayButton)};
  font-weight: 600;
  border-bottom: ${({ isSelected, theme }) => isSelected && `1px solid ${theme.colors.black}`};
  padding-bottom: 10px;
  cursor: pointer;
`

interface TradingPairsComponentProps {
  tradingPair: string
  tickerList: ITicker[] | null
}

export const TradingPairsComponent: React.FC<TradingPairsComponentProps> = ({
  tradingPair,
  tickerList,
}) => {
  const { t } = useTranslation()

  const [searchCoinVal, setSearchCoinVal] = useState('')

  const [lastPriceToTopSort, setLastPriceToTopSort] = useState(false)
  const [lastPriceToBotSort, setLastPriceToBotSort] = useState(false)
  const sortToTop = () => {
    if (!lastPriceToTopSort) {
      setLastPriceToBotSort(false)
      setLastPriceToTopSort(true)
      setH24ToBotSort(false)
      setH24ToTopSort(false)
    } else {
      setLastPriceToBotSort(false)
      setLastPriceToTopSort(false)
      setH24ToBotSort(false)
      setH24ToTopSort(false)
    }
  }
  const sortToBot = () => {
    if (!lastPriceToBotSort) {
      setLastPriceToBotSort(true)
      setLastPriceToTopSort(false)
      setH24ToBotSort(false)
      setH24ToTopSort(false)
    } else {
      setLastPriceToBotSort(false)
      setLastPriceToTopSort(false)
      setH24ToBotSort(false)
      setH24ToTopSort(false)
    }
  }

  const [h24ToTopSort, setH24ToTopSort] = useState(false)
  const [h24ToBotSort, setH24ToBotSort] = useState(false)
  const sortToTop24h = () => {
    if (!h24ToTopSort) {
      setH24ToBotSort(false)
      setH24ToTopSort(true)
      setLastPriceToBotSort(false)
      setLastPriceToTopSort(false)
    } else {
      setH24ToBotSort(false)
      setH24ToTopSort(false)
      setLastPriceToBotSort(false)
      setLastPriceToTopSort(false)
    }
  }
  const sortToBot24h = () => {
    if (!h24ToBotSort) {
      setH24ToBotSort(true)
      setH24ToTopSort(false)
      setLastPriceToBotSort(false)
      setLastPriceToTopSort(false)
    } else {
      setH24ToBotSort(false)
      setH24ToTopSort(false)
      setLastPriceToBotSort(false)
      setLastPriceToTopSort(false)
    }
  }

  const marketListWithPrices = useMemo(
    () =>
      marketList?.map(m => {
        const pairName = m?.link?.toUpperCase().split('/')[2] || ''
        const tokenPriceData = getMarketDataByPair(tickerList, pairName)
        return {
          ...m,
          lastPrice: tokenPriceData?.lastPrice,
          h24change: tokenPriceData?.price24hPcnt,
        }
      }),
    [tickerList]
  )
  const sortedBy24hChange =
    ((h24ToTopSort || h24ToBotSort) &&
      marketListWithPrices?.sort((a, b) =>
        h24ToTopSort ? a.h24change - b.h24change : b.h24change - a.h24change
      )) ||
    marketListWithPrices
  const sortedMarketItems =
    ((lastPriceToTopSort || lastPriceToBotSort) &&
      sortedBy24hChange?.sort((a, b) =>
        lastPriceToTopSort ? a.lastPrice - b.lastPrice : b.lastPrice - a.lastPrice
      )) ||
    sortedBy24hChange
  const markets =
    lastPriceToTopSort || lastPriceToBotSort || h24ToBotSort || h24ToTopSort
      ? sortedMarketItems
      : marketList
  const searchedMarketItems =
    (searchCoinVal &&
      markets?.filter(m => m.pair?.toLowerCase()?.includes(searchCoinVal?.toLowerCase()))) ||
    markets

  const [favTradingPairs, setFavTradingPairs] = useState([])
  useEffect(() => {
    const storedPairs = JSON.parse(localStorage.getItem('favTradingPairs')) || []
    setFavTradingPairs(storedPairs)
  }, [])
  const addPairToFav = (e, pairNameString) => {
    e.preventDefault()
    let newArray
    if (!favTradingPairs.includes(pairNameString)) {
      newArray = [...favTradingPairs, pairNameString]
    } else {
      newArray = favTradingPairs.filter(x => x !== pairNameString)
    }
    setFavTradingPairs(newArray)
    localStorage.setItem('favTradingPairs', JSON.stringify(newArray))
  }

  enum ListCategory {
    'favorites',
    'spot',
  }

  const [listCategory, setListCategory] = useState(ListCategory.spot)
  const getPairNameString = link => {
    const pairName = link.toUpperCase().split('/')[2] || ''
    const pairData = getActiveMarketPair(pairName)
    return pairData?.pair || ''
  }
  const marketItemsByCategory =
    (listCategory === ListCategory.favorites &&
      searchedMarketItems?.filter(x =>
        favTradingPairs?.some(pair => pair === getPairNameString(x.link))
      )) ||
    searchedMarketItems

  return (
    <>
      <BlockHeader>
        <MarketTextBlack style={{ marginLeft: 15 }}>{t('menu.markets')}</MarketTextBlack>
      </BlockHeader>
      <SearchWrapper>
        <SearchValueInput
          setValue={setSearchCoinVal}
          value={searchCoinVal}
          image={'/images/interface/search-icon.png'}
        />
      </SearchWrapper>
      <TradingPairsCategories>
        <SelectCategoryButton
          isSelected={listCategory === ListCategory.favorites}
          onClick={() => setListCategory(ListCategory.favorites)}
        >
          {t('market.favorites')}
        </SelectCategoryButton>
        <SelectCategoryButton
          isSelected={listCategory === ListCategory.spot}
          onClick={() => setListCategory(ListCategory.spot)}
        >
          {t('market.spot')}
        </SelectCategoryButton>
      </TradingPairsCategories>
      <TradingPairsHeader>
        <AppTextMini style={{ width: 100, textAlign: 'left' }}>{t('market.name')}</AppTextMini>
        <FlexRow style={{ width: 100, justifyContent: 'end' }}>
          <AppTextMini style={{ textAlign: 'right' }}>{t('market.price')}</AppTextMini>
          <TopDownSelector
            clickOnBot={sortToBot}
            clickOnTop={sortToTop}
            isTopClicked={lastPriceToTopSort}
            isBottomClicked={lastPriceToBotSort}
          />
        </FlexRow>
        <FlexRow style={{ width: 70, justifyContent: 'end' }}>
          <AppTextMini style={{ textAlign: 'right' }}>{t('market.h24')}</AppTextMini>
          <TopDownSelector
            clickOnBot={sortToBot24h}
            clickOnTop={sortToTop24h}
            isTopClicked={h24ToTopSort}
            isBottomClicked={h24ToBotSort}
          />
        </FlexRow>
      </TradingPairsHeader>
      <TradingPairsBlock>
        {marketItemsByCategory?.map((token, id) => (
          <TradingPair
            addPairToFav={addPairToFav}
            tickerList={tickerList}
            activePair={tradingPair}
            link={token.link}
            key={token.pair}
            favTradingPairs={favTradingPairs}
          />
        ))}
      </TradingPairsBlock>
    </>
  )
}
