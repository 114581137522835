import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexRow } from '../../../style/projectComponents'
import styled from 'styled-components/macro'

const CheckmarkWrapper = styled(FlexRow)`
  height: 24px;
  width: 24px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  margin-right: 14px;
  cursor: pointer;
`

interface CheckmarkProps {
  isClicked: boolean
}

const Checkmark = styled.div<CheckmarkProps>`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: ${({ isClicked, theme }) => (isClicked ? theme.colors.black : 'none')};
`

const Text = styled.div<{ xl?: boolean }>`
  width: 260px;
  font-size: ${({ xl }) => (xl ? '16px' : '11px')};
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
`

export const CheckComponent: FC<{
  isClicked
  handleClick
  text?: string
  textXl?: boolean
}> = ({ isClicked, handleClick, text = 'auth.noUsCitizen', textXl = false }) => {
  const { t, i18n } = useTranslation()

  return (
    <FlexRow className={'checkbox'} style={{ marginBottom: 10, marginTop: 5 }}>
      <CheckmarkWrapper onClick={handleClick}>
        <Checkmark isClicked={isClicked} />
      </CheckmarkWrapper>
      <Text xl={textXl}>{t(text)}</Text>
    </FlexRow>
  )
}
