import React, { useEffect } from 'react'
import { FlexColumn, Page } from '../../style/projectComponents'
import styled from 'styled-components/macro'
import { SettingsInputs } from '../../components/SettingsComponents/SettingsInputs'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { TwoFactorBlock } from '../../components/SettingsComponents/TwoFactorBlock'

const StyledBg = styled(FlexColumn)`
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 110px;
`

const SettingsTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 30px;
`

const SettingsPage: React.FC = () => {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Page>
      <StyledBg>
        <SettingsTitle>{t('settings.title')}</SettingsTitle>
        <SettingsInputs />
        <TwoFactorBlock />
      </StyledBg>
    </Page>
  )
}

export default observer(SettingsPage)
