export const currenciesList = [
  { code: 'usd', description: 'United States Dollar' },
  { code: 'aed', description: 'United Arab Emirates Dirham' },
  { code: 'ars', description: 'Argentine Peso' },
  { code: 'aud', description: 'Australian Dollar' },
  { code: 'bdt', description: 'Bangladeshi Taka' },
  { code: 'bhd', description: 'Bahraini Dinar' },
  { code: 'bmd', description: 'Bermudian Dollar' },
  { code: 'brl', description: 'Brazilian Real' },
  { code: 'cad', description: 'Canadian Dollar' },
  { code: 'chf', description: 'Swiss Franc' },
  { code: 'clp', description: 'Chilean Peso' },
  { code: 'cny', description: 'Chinese Yuan' },
  { code: 'czk', description: 'Czech Koruna' },
  { code: 'dkk', description: 'Danish Krone' },
  { code: 'eur', description: 'Euro' },
  { code: 'gbp', description: 'British Pound Sterling' },
  { code: 'gel', description: 'Georgian Lari' },
  { code: 'hkd', description: 'Hong Kong Dollar' },
  { code: 'huf', description: 'Hungarian Forint' },
  { code: 'idr', description: 'Indonesian Rupiah' },
  { code: 'ils', description: 'Israeli New Shekel' },
  { code: 'inr', description: 'Indian Rupee' },
  { code: 'jpy', description: 'Japanese Yen' },
  { code: 'krw', description: 'South Korean Won' },
  { code: 'kwd', description: 'Kuwaiti Dinar' },
  { code: 'lkr', description: 'Sri Lankan Rupee' },
  { code: 'mmk', description: 'Myanmar Kyat' },
  { code: 'mxn', description: 'Mexican Peso' },
  { code: 'myr', description: 'Malaysian Ringgit' },
  { code: 'ngn', description: 'Nigerian Naira' },
  { code: 'nok', description: 'Norwegian Krone' },
  { code: 'nzd', description: 'New Zealand Dollar' },
  { code: 'php', description: 'Philippine Peso' },
  { code: 'pkr', description: 'Pakistani Rupee' },
  { code: 'pln', description: 'Polish Zloty' },
  { code: 'rub', description: 'Russian Ruble' },
  { code: 'sar', description: 'Saudi Riyal' },
  { code: 'sek', description: 'Swedish Krona' },
  { code: 'sgd', description: 'Singapore Dollar' },
  { code: 'thb', description: 'Thai Baht' },
  { code: 'try', description: 'Turkish Lira' },
  { code: 'twd', description: 'New Taiwan Dollar' },
  { code: 'uah', description: 'Ukrainian Hryvnia' },
  { code: 'vef', description: 'Venezuelan Bolívar' },
  { code: 'vnd', description: 'Vietnamese Dong' },
  { code: 'zar', description: 'South African Rand' },
]
