import {
  AppTextMini,
  AppTextNormal,
  CloseModalButton,
  ErrorText,
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  FlexRowBetween,
  StyledButton as StyleButtonDef,
  TabsButton as TabsButtonDef,
} from '../../style/projectComponents'
import {
  StyledButton as StyledButtonTime,
  TabsButton as TabsButtonTime,
} from '../../style/projectTimeComponents'
import styled from 'styled-components/macro'
import { ValidationInputComponent } from '../../components/global/ValidationInputComponent'
import { FC, useCallback, useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore'
import InvestPoolApi from '../../service/investpool-api/InvestPoolApi'
import {
  AvailablePeriods,
  AvailableTerms,
  ICreateInvestPoolReq,
  InvestPoolTerm,
  IPeriod,
} from '../../service/investpool-api/models'
import { CheckComponent } from '../../components/auth/Register/CheckComponent'
import SelectionPanel from '../../components/global/SelectionPanel'
import RemovableTag from '../../components/global/RemovableTag'
import {
  addElementIfNotExists,
  deleteElementById,
  toggleNumberInArray,
} from '../../utils/deleteElementById'
import AdminApi from '../../service/admin-api/AdminApi'
import { DropdownComponent } from '../../components/global/DropdownComponent'
import { SetImageComponent } from '../../components/global/SetImageComponent'
import { ValidationTextAreaComponent } from '../../components/global/ValidationTextAreaComponent'
import { PROJECT_NAME } from '../../config/serverConstants'
import { queryClient } from '../../config/query-client'
import { getGetApiInvestPoolGetAllInvestPoolsQueryKey } from '../../service/api/generated'
import { filesCompression } from '../../utils/filesCompression'

const StyledButton = PROJECT_NAME === 'Def' ? StyleButtonDef : StyledButtonTime

const TabsButton = PROJECT_NAME === 'Def' ? TabsButtonDef : TabsButtonTime
const TagsList = styled(FlexRow)`
  gap: 6px;
  flex-wrap: wrap;
  justify-content: start;
`

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 100%;
  @media (max-width: 480px) {
    label {
      left: 20px;
    }
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: ${({ theme }) => theme.colors.modalBg};
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  box-sizing: border-box;
  justify-content: start;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding-top: 100px;
`

const Content = styled(FlexColumnStart)`
  z-index: 1100;
  background: ${({ theme }) => theme.colors.gray050};
  border-radius: 16px;
  padding: 20px 25px;
  position: relative;
  width: clamp(320px, 100%, 800px);
  align-items: center;

  @media (max-width: 480px) {
    padding: 15px;
    align-items: center;
  }
`

const InnerWrapper = styled(FlexColumnStart)`
  row-gap: 0.75rem;
  width: clamp(300px, 100%, 760px);

  h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
  }

  h2 {
    font-size: 20px;
    width: 100%;
  }
`

export const AdminCreateInvestPool: FC<{ setIsOpen?: any }> = props => {
  const { setIsOpen } = props

  const {
    control,
    clearErrors,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
  } = useForm()

  const GlobalModalStorage = useContext(GlobalModalStore)

  const createInvestPool = useMutation(
    (data: ICreateInvestPoolReq) => InvestPoolApi.createInvestPool(data),
    {
      onSuccess: data => {
        setIsOpen(false)
        GlobalModalStorage.makeVisible(true, t('common.success'))
        console.log(data)
        queryClient.invalidateQueries({
          queryKey: getGetApiInvestPoolGetAllInvestPoolsQueryKey(),
        })
      },
      onError: error => {
        GlobalModalStorage.makeVisible(false, t('common.error'))
        console.log(error)
      },
    }
  )

  const [selectedPeriodId, setSelectedPeriodId] = useState(0)
  const [selectedTermId, setSelectedTermId] = useState(0)

  const [tags, setTags] = useState([])
  const [tagValue, setTagValue] = useState('')
  const handleChangeTagValue = e => setTagValue(e.target.value)
  const removeTagFunc = id => deleteElementById(id, tags, setTags)
  const addTagFunc = () => addElementIfNotExists(tags, setTags, tagValue)

  const [tokens, setTokens] = useState([])
  const removeTokenFunc = id => deleteElementById(id, tokens, setTokens)

  const { t } = useTranslation()

  const { data: currencyList } = useQuery('currency_list', () =>
    AdminApi.getCurrencies({
      skip: 0,
      take: 100,
    })
  )
  const currencyItems = currencyList?.currencies || []
  const currencyNames = currencyItems?.map(c => c?.name) || []

  const [openCurrencyMenu, setOpenCurrencyMenu] = useState(false)
  const [currenciesIds, setCurrenciesIds] = useState([0])
  const currenciesIdsData = currenciesIds?.map(curId => currencyItems[curId]?.id)
  const currenciesNames = currenciesIds?.map(curId => currencyItems[curId])
  const handleChangeCurrencyId = (id: number) => {
    toggleNumberInArray(currenciesIds, setCurrenciesIds, id)
  }

  const handleChangeCurrency = (curId: string) => {
    toggleNumberInArray(
      currenciesIds,
      setCurrenciesIds,
      currencyItems.findIndex(c => c.id === curId)
    )
  }

  const updateImage = useCallback(async (event: any, setIsFilesGood, setFileBinary, setFileUrl) => {
    let isFilesGood = true
    const addedFiles = event.target.files
    let url = ''
    let uploadableFile = 0
    const checkAllFiles = async () => {
      url = URL.createObjectURL(addedFiles[0])
      uploadableFile = addedFiles[0]
      const fileSizeInMB = addedFiles[0]?.size / 1024 / 1000
      const availableMaxSizeMB = 2.5 // Mb
      if (
        addedFiles &&
        addedFiles.length > 0 &&
        fileSizeInMB <= availableMaxSizeMB &&
        (addedFiles[0].type === 'image/jpeg' ||
          addedFiles[0].type === 'image/jpg' ||
          addedFiles[0].type === 'image/png')
      ) {
        console.log('good file')
      } else {
        GlobalModalStorage.makeVisible(false, t('common.imageSizeOrTypeError'))
        setIsFilesGood(false)
        setFileBinary(null)
        setFileUrl(null)
        isFilesGood = false
      }
    }
    if (addedFiles && addedFiles.length > 0 && isFilesGood) {
      const { compressedFile } = await filesCompression(addedFiles[0])
      await checkAllFiles()
      setFileBinary(compressedFile)
      setFileUrl(url)
    }
  }, [])

  const [logoBinary, setLogoBinary] = useState(null)
  const [logoUrl, setLogoUrl] = useState(null)
  const [isFilesGood, setIsFilesGood] = useState(true)
  const [imageBinary, setImageBinary] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const changeLogoImage = (event: any) =>
    updateImage(event, setIsFilesGood, setLogoBinary, setLogoUrl)
  const changeImage = (event: any) =>
    updateImage(event, setIsFilesGood, setImageBinary, setImageUrl)

  const onSubmit = (data: any) => {
    const newData: ICreateInvestPoolReq = {
      name: data.title,
      description: data.description,
      logo: logoBinary,
      image: imageBinary,
      tags: tags,
      availableCurrenciesIds: currenciesIdsData,
      period: AvailablePeriods[selectedPeriodId].type as IPeriod,
      term: AvailableTerms[selectedTermId].type as InvestPoolTerm,
      firstApr: data.firstApr,
      secondApr: data.secondApr,
      userLimit: data.userLimit,
      totalLimit: data.totalLimit,
      fairAccrual: data.fairAccrual,
      enableAccrualInterestFromReferal: data.enableAccrualInterestFromReferal,
      holdInDays: data.holdInDays,
    }
    createInvestPool.mutate(newData)
  }
  return (
    <StyledForm
      onSubmit={handleSubmit(onSubmit)}
      onClick={e => {
        if (e.target === e.currentTarget) {
          setIsOpen(false)
        }
      }}
    >
      <Content>
        <InnerWrapper>
          <FlexRowBetween style={{ alignItems: 'center' }}>
            <h3 style={{ margin: 0, padding: 0 }}>Create Invest Pool</h3>
            <CloseModalButton onClick={() => setIsOpen(false)}>
              <img src={'/images/ic_cancel.svg'} />
            </CloseModalButton>
          </FlexRowBetween>

          <FlexColumn>
            <AppTextNormal style={{ width: '100%', textAlign: 'center', marginBottom: 16 }}>
              Logo
            </AppTextNormal>
            <SetImageComponent fileUrl={logoUrl} handleChangeFile={changeLogoImage} />
            <AppTextMini>Max size 2.5MB</AppTextMini>
          </FlexColumn>

          <FlexColumn style={{ width: '100%' }}>
            <AppTextNormal style={{ width: '100%', textAlign: 'center', marginBottom: 16 }}>
              Image
            </AppTextNormal>
            <SetImageComponent fileUrl={imageUrl} handleChangeFile={changeImage} fullSize />
            <AppTextMini>Max size 2.5MB</AppTextMini>
          </FlexColumn>

          <InputWrapper>
            <Controller
              name="title"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  type={'text'}
                  isError={!!errors['title']}
                  label="Title"
                />
              )}
            />
          </InputWrapper>
          {errors?.title?.message && <ErrorText>{t(errors?.title?.message.toString())}</ErrorText>}
          <InputWrapper>
            <Controller
              name="description"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <ValidationTextAreaComponent
                  {...field}
                  type={'text'}
                  isError={!!errors['description']}
                  label="Description"
                />
              )}
            />
          </InputWrapper>
          {errors?.description?.message && (
            <ErrorText>{t(errors?.description?.message.toString())}</ErrorText>
          )}
          <InputWrapper>
            <Controller
              name="addTag"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  type="text"
                  value={tagValue}
                  onChange={handleChangeTagValue}
                  isError={!!errors['addTag']}
                  label="Add Tag"
                />
              )}
            />
          </InputWrapper>
          <StyledButton
            onClick={addTagFunc}
            variant="black"
            type="button"
            style={{ width: '100%', padding: '1rem' }}
            disabled={!tagValue}
          >
            Add Tag
          </StyledButton>
          <TagsList style={{ width: '100%' }}>
            {tags.map((tag, id) => (
              <RemovableTag
                id={id}
                key={`${tag.id}+${tag}`}
                content={tag}
                removeTag={removeTagFunc}
              />
            ))}
          </TagsList>
          <FlexRow style={{ gap: '10px' }}>
            {currenciesNames.length > 0 &&
              currenciesNames.map((curr, idx) => (
                <TabsButton key={idx} onClick={() => handleChangeCurrency(curr.id)} clicked={true}>
                  {curr?.name}
                </TabsButton>
              ))}
          </FlexRow>
          <DropdownComponent
            openMenu={openCurrencyMenu}
            setOpenMenu={setOpenCurrencyMenu}
            itemId={0}
            activeItems={currenciesIds}
            setId={handleChangeCurrencyId}
            itemList={currencyNames}
          />
          <TagsList style={{ width: '100%' }}>
            {tokens.map(token => (
              <RemovableTag
                id={token.id}
                key={`${token.id}+${token}`}
                content={token}
                removeTag={removeTokenFunc}
              />
            ))}
          </TagsList>
          <SelectionPanel
            title="Period"
            elements={AvailablePeriods.map(i => i.title)}
            selectedId={selectedPeriodId}
            selectElement={setSelectedPeriodId}
          />
          <SelectionPanel
            title="Terms"
            elements={AvailableTerms.map(i => i.title)}
            selectedId={selectedTermId}
            selectElement={setSelectedTermId}
          />
          <InputWrapper style={{ marginTop: 24 }}>
            <Controller
              name="firstApr"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  type={'number'}
                  maxLength={6}
                  isError={!!errors['firstApr']}
                  label="First APR"
                />
              )}
            />
          </InputWrapper>
          {errors?.firstApr?.message && (
            <ErrorText>{t(errors?.firstApr?.message.toString())}</ErrorText>
          )}
          {selectedTermId === 1 && (
            <>
              <InputWrapper>
                <Controller
                  name="secondApr"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Required',
                    },
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <ValidationInputComponent
                      {...field}
                      type={'number'}
                      maxLength={6}
                      isError={!!errors['secondApr']}
                      label="Second APR"
                    />
                  )}
                />
              </InputWrapper>
              {errors?.secondApr?.message && (
                <ErrorText>{t(errors?.secondApr?.message.toString())}</ErrorText>
              )}
            </>
          )}
          <InputWrapper>
            <Controller
              name="holdInDays"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  type={'number'}
                  maxLength={6}
                  isError={!!errors['holdInDays']}
                  label="Hold in days"
                />
              )}
            />
          </InputWrapper>
          {errors?.holdInDays?.message && (
            <ErrorText>{t(errors?.holdInDays?.message.toString())}</ErrorText>
          )}
          <InputWrapper>
            <Controller
              name="userLimit"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  type={'number'}
                  maxLength={6}
                  isError={!!errors['userLimit']}
                  label="User Limit"
                />
              )}
            />
          </InputWrapper>
          {errors?.userLimit?.message && (
            <ErrorText>{t(errors?.userLimit?.message.toString())}</ErrorText>
          )}
          <InputWrapper>
            <Controller
              name="totalLimit"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  type={'number'}
                  maxLength={6}
                  isError={!!errors['totalLimit']}
                  label="Total Limit"
                />
              )}
            />
          </InputWrapper>
          {errors?.totalLimit?.message && (
            <ErrorText>{t(errors?.totalLimit?.message.toString())}</ErrorText>
          )}
          <CheckComponent
            textXl={true}
            text="Activate accrual interest from referral"
            isClicked={watch('enableAccrualInterestFromReferal')}
            handleClick={() =>
              setValue(
                'enableAccrualInterestFromReferal',
                !watch('enableAccrualInterestFromReferal')
              )
            }
          />
          <CheckComponent
            textXl={true}
            text="Accruals will depend on the number of user days from the deposit date to the accrual date."
            isClicked={watch('fairAccrual')}
            handleClick={() => setValue('fairAccrual', !watch('fairAccrual'))}
          />

          <StyledButton
            onClick={() => clearErrors()}
            variant="black"
            type={'submit'}
            noRadius
            disabled={!isDirty || createInvestPool.isLoading || !isFilesGood}
            style={{ width: '100%', padding: '1rem' }}
          >
            Create Pool
          </StyledButton>
        </InnerWrapper>
      </Content>
    </StyledForm>
  )
}
