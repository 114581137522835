/* eslint-disable @typescript-eslint/no-redeclare */
import { ErrorText, FlexColumn, FlexRow, StyledButton } from '../../../style/projectComponents'
import { ComponentPropsWithoutRef, FC, useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import { ValidationInputComponent } from '../../global/ValidationInputComponent'
import { IUserDetailsResponse } from '../../../service/user-api/models'
import { useMutation, useQuery } from 'react-query'
import AdminApi from '../../../service/admin-api/AdminApi'
import { IDepositMoneyRequest } from '../../../service/admin-api/models'
import { useTranslation } from 'react-i18next'
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore'
import { DropdownComponent } from '../../global/DropdownComponent'

type BalanceAddForm = {
  user: IUserDetailsResponse
}

type IFormType = {
  name: 'sum'
  rules: any
  type: string
}

const forms: IFormType[] = [
  {
    name: 'sum',
    rules: {
      required: true,
      valueAsNumber: true,
      pattern: {
        value: /^-?\d{1,12}(?:\.\d{1,18})?$/,
        message: 'adminUserDetail.inputRule',
      },
    },
    type: 'number',
  },
]

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;
  @media (max-width: 480px) {
    width: 100%;
    max-width: 380px;
    min-width: 280px;
    label {
      left: 20px;
    }
  }
`

const StyledForm = styled.form`
  flex-direction: column;
  display: flex;
  align-items: start;
  border: 1px solid ${({ theme }) => theme.colors.lightGrayBg};
  border-radius: var(--8, 0.5rem);
  padding: 1rem var(--4, 2rem) 3rem var(--4, 2rem);
  margin: 10px;

  .inps {
    gap: 0.81rem;
    margin-bottom: 0.81rem;

    .checkbox {
      width: 100%;
      justify-content: left;
    }
  }

  @media (max-width: 780px) {
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px;
  }
`

export const BalanceAddForm: FC<BalanceAddForm & ComponentPropsWithoutRef<'form'>> = props => {
  const { user, ...other } = props
  const {
    control,
    reset,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm()

  const depositMoney = useMutation((data: IDepositMoneyRequest) => AdminApi.depositMoney(data))
  const [currencyList, setCurrencyList] = useState(null)
  const currencyItems = currencyList?.currencies || []
  const currencyNames = currencyItems.map(c => c.name) || []
  const [currencyId, setCurrencyId] = useState<any>(0)
  const handleChangeCurrencyId = (id: number) => {
    setCurrencyId(id)
  }
  const userId = user.id

  const GetCurrenciesList = useQuery(
    'currency_list',
    () =>
      AdminApi.getCurrencies({
        skip: 0,
        take: 30,
      }),
    {
      onSuccess: data => {
        setCurrencyList(data)
      },
    }
  )

  const GlobalModalStorage = useContext(GlobalModalStore)

  const onSubmit = (data: any) => {
    depositMoney.mutate(
      {
        userId: userId,
        currencyId: currencyItems[currencyId]?.id ?? '',
        amount: Number(parseFloat(data['sum']).toFixed(18)),
        comment: 'Deposit',
      },
      {
        onSuccess: data => {
          GlobalModalStorage.makeVisible(true, t('common.success'))
          reset({
            sum: '',
          })
        },
        onError: error => {
          GlobalModalStorage.makeVisible(false, t('common.error'))
        },
      }
    )
  }

  const { t, i18n } = useTranslation()
  const [openCurrencyMenu, setOpenCurrencyMenu] = useState(false)

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <h3 style={{ marginBottom: '1rem', fontSize: '1.5rem', fontWeight: 400 }}>
        {t('adminUserDetail.addBalance')}
      </h3>
      <FlexColumn className={'inps'}>
        {forms.map((it, idx) => (
          <InputWrapper key={idx}>
            <Controller
              name={it.name}
              control={control}
              rules={it.rules}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  maxLength={10}
                  isError={!!errors[it.name]}
                  label={t(`adminUserDetail.${it.name}.label`)}
                />
              )}
            />
          </InputWrapper>
        ))}
        <DropdownComponent
          openMenu={openCurrencyMenu}
          setOpenMenu={setOpenCurrencyMenu}
          itemId={currencyId}
          setId={handleChangeCurrencyId}
          itemList={currencyNames}
        />
      </FlexColumn>

      {!!errors['sum'] && (
        <ErrorText style={{ marginBottom: 10 }}>{t(errors['sum']?.message.toString())}</ErrorText>
      )}

      <StyledButton
        type={'submit'}
        variant="black"
        style={{ width: '100%', padding: 'var(--16, 1rem)', maxWidth: 385 }}
        disabled={!isDirty || depositMoney.isLoading}
        noRadius
      >
        {depositMoney.isLoading ? t('adminPercent.processus') : t('adminPercent.add')}
      </StyledButton>
    </StyledForm>
  )
}
