import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { AppStyle } from './style/projectComponents'
import { observer } from 'mobx-react-lite'
import UserStorage from './storage/UserStorage/UserStorage'
import AppProvider from './AppProvider'
import { adminRoutes, privateRoutes, publicRoutes } from './utils/routes'
import { Footer } from './components/Home/Footer/Footer'
import HeaderComponent from './components/global/HeaderComponent/HeaderComponent'
import { ThemeProvider } from 'styled-components'
import { LightStyle, DarkStyle } from './style/theme'
import GlobalStyle from './style/Global'
import { PROJECT_NAME } from './config/serverConstants'
import GlobalTimeStyle from './style/GlobalTimeStyles'

function App() {
  const UserStore = useContext(UserStorage)
  const isAdmin = UserStore?.isAdmin()
  const isAuth = !!UserStore?.accessToken

  const [theme, setTheme] = useState('light')
  const isDarkTheme = theme === 'dark'

  const toggleTheme = () => {
    const updatedTheme = isDarkTheme ? 'light' : 'dark'
    setTheme(updatedTheme)
    localStorage.setItem('theme', updatedTheme)
  }

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme')
    if (savedTheme && ['dark', 'light'].includes(savedTheme)) {
      setTheme(savedTheme)
      localStorage.setItem('theme', savedTheme)
    } else {
      setTheme('dark')
      localStorage.setItem('theme', 'dark')
    }
  }, [])

  return (
    <ThemeProvider theme={isDarkTheme ? DarkStyle : LightStyle}>
      <AppProvider>
        <AppStyle isAuth={isAuth}>
          <BrowserRouter>
            {PROJECT_NAME === 'Def' ? <GlobalStyle /> : <GlobalTimeStyle />}
            <HeaderComponent toggleTheme={toggleTheme} isDarkTheme={isDarkTheme} />
            {!!UserStore.accessToken ? (
              <Routes>
                {privateRoutes.map((it, idx) => (
                  <Route path={it.path} Component={it.component} key={idx} />
                ))}
                <Route path="/*" element={<Navigate to={'/dashboard'} replace />} />
                {isAdmin && (
                  <>
                    {adminRoutes.map((it, idx) => (
                      <Route path={it.path} Component={it.component} key={idx} />
                    ))}
                    <Route path="/admin/*" element={<Navigate to={'/admin/analytics'} replace />} />
                    <Route
                      Component={() => {
                        window.location.href = 'https://landing.time.investments'

                        return null
                      }}
                      path={'/landing'}
                    />
                  </>
                )}
              </Routes>
            ) : (
              <Routes>
                {publicRoutes.map((it, idx) => (
                  <Route path={it.path} Component={it.component} key={idx} />
                ))}
                <Route path="/*" element={<Navigate to={'/login'} replace />} />
                <Route
                  Component={() => {
                    window.location.href = 'https://landing.time.investments'

                    return null
                  }}
                  path={'/landing'}
                />
              </Routes>
            )}
            <Footer />
          </BrowserRouter>
        </AppStyle>
      </AppProvider>
    </ThemeProvider>
  )
}

export default observer(App)
