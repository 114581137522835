import React from 'react'
import styled from 'styled-components'
import { FlexRowBetween, StyledInput } from '../../style/projectComponents'
import { useTranslation } from 'react-i18next'

interface SearchValueInputProps {
  value: string
  setValue: any
  image: string
}

const StyledInputWrapper = styled(FlexRowBetween)`
  align-items: center;
  background: ${({ theme }) => theme.colors.inputBackground};
  border-radius: 8px;
`
const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-left: 16px;
  filter: ${({ theme }) => theme.colors.black === '#fff' && 'invert(1)'};
`

export const SearchValueInput: React.FC<SearchValueInputProps> = ({ value, setValue, image }) => {
  const { t } = useTranslation()

  return (
    <StyledInputWrapper>
      <Icon src={image} alt="" />
      <StyledInput
        style={{ width: 'calc(100% - 40px)', padding: 10, fontSize: 13, border: 'none' }}
        type={'text'}
        value={value}
        placeholder={t('market.searchCrypto')}
        onChange={e => {
          setValue(e.target.value)
        }}
      />
    </StyledInputWrapper>
  )
}
