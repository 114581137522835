import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { FlexColumnStart, FlexRow, FlexRowBetween } from '../../style/projectComponents'
import { useTranslation } from 'react-i18next'
import { OperationItem } from './OperationItem'
import { useLocation } from 'react-router-dom'

const Table = styled(FlexColumnStart)`
  width: 100%;

  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    max-width: available;
    overflow: auto;
    padding: 0;
    margin: 0;
  }
`

const Block = styled(FlexRowBetween)`
  height: 45px;
  align-items: center;
  background: ${({ theme }) => theme.colors.gray050};
  /* width: calc(100% - 40px); */
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
    gap: 30px;
  }

  p {
    font-size: 13px;
    text-align: left;
    font-weight: 500;
    margin: 0;
  }
`

const Content = styled(FlexColumnStart)`
  @media (max-width: 768px) {
    min-width: 100%;
    width: auto;
  }
  width: 100%;
`

const EndOfTable = styled(FlexRow)`
  width: 100%;
  background: ${({ theme }) => theme.colors.gray050};
  height: 20px;
  border-radius: 0 0 20px 20px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

interface OperationsComponentProps {
  operations: any
  onClickItem?: (data: any) => void
  keys?: any[]
  isCreateCurrency?: boolean
  withTopBorder?: boolean
  isAdminWallets?: boolean
  updateWalletMutate?: any
  updateWalletMutateData?: any
  walletsValidationRegex?: string
}

export const OperationsComponent: React.FC<OperationsComponentProps> = ({
  operations,
  keys = ['dashboard.date', 'dashboard.amount', 'dashboard.operation', 'status'],
  onClickItem = () => {},
  isCreateCurrency,
  withTopBorder,
  isAdminWallets,
  updateWalletMutate,
  updateWalletMutateData,
  walletsValidationRegex,
}) => {
  const { t } = useTranslation()

  const [openErrorMessageId, setOpenErrorMessageId] = useState(null)
  const [openErrorMessage, setOpenErrorMessage] = useState(false)

  const location = useLocation()

  const getBlockWidth = it => (it.includes('evm') && 50) || 160

  return (
    <Table>
      <Content>
        <Block
          style={{
            borderRadius: withTopBorder && '20px 20px 0 0',
            paddingTop: 20,
          }}
        >
          {keys.map((it, idx) => (
            <p
              key={idx}
              style={{
                width: getBlockWidth(it),
                textAlign: idx === keys.length - 1 ? 'right' : 'left',
              }}
            >
              {t(`common.${it}`)}
            </p>
          ))}
        </Block>
        {operations?.map((operation, id) => (
          <OperationItem
            style={{
              cursor: location.pathname === '/admin/analytics' && 'pointer',
            }}
            keys={keys}
            isCreateCurrency={isCreateCurrency}
            onClick={() => onClickItem(operation)}
            openErrorMessage={openErrorMessage}
            setOpenErrorMessage={setOpenErrorMessage}
            setOpenErrorMessageId={setOpenErrorMessageId}
            openErrorMessageId={openErrorMessageId}
            key={id}
            operation={operation}
            id={id}
            isAdminWallets={isAdminWallets}
            updateWalletMutate={updateWalletMutate}
            updateWalletMutateData={updateWalletMutateData}
            walletsValidationRegex={walletsValidationRegex}
          />
        ))}
        <EndOfTable />
      </Content>
    </Table>
  )
}
