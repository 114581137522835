import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as DefComponents from '../../../style/projectComponents'
import * as TimeComponents from '../../../style/projectTimeComponents'
import { observer } from 'mobx-react-lite'
import MenuModal from '../modal/MenuModal'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import { useTranslation } from 'react-i18next'
import { Link as ScrollLink } from 'react-scroll'
import LanguageModal from '../modal/LanguageModal'
import { LogoutModal } from '../modal/LogoutModal'
import { headerAuthLinks } from '../headerLinks'
import { HeaderImgDef } from './images/HeaderImgDef'
import { PROJECT_NAME } from '../../../config/serverConstants'
import { HeaderImgTime } from './images/HeaderImgTime'
import * as Time from './styles/HeaderStylesTime'
import * as Def from './styles/HeaderStylesDef'

interface HeaderComponentProps {
  toggleTheme: any
  isDarkTheme: boolean
}

const StyledButton =
  PROJECT_NAME === 'Def' ? DefComponents.StyledButton : TimeComponents.StyledButton
const Header = PROJECT_NAME === 'Def' ? Def.Header : Time.Header
const AppMenu = PROJECT_NAME === 'Def' ? Def.AppMenu : Time.AppMenu
const BtnWrapper = PROJECT_NAME === 'Def' ? Def.BtnWrapper : Time.BtnWrapper
const Content = PROJECT_NAME === 'Def' ? Def.Content : Time.Content
const ExitButton = PROJECT_NAME === 'Def' ? Def.ExitButton : Time.ExitButton
const HeaderLink = PROJECT_NAME === 'Def' ? Def.HeaderLink : Time.HeaderLink
const LinksWrapper = PROJECT_NAME === 'Def' ? Def.LinksWrapper : Time.LinksWrapper
const Logo = PROJECT_NAME === 'Def' ? Def.Logo : Time.Logo
const RightWrapper = PROJECT_NAME === 'Def' ? Def.RightWrapper : Time.RightWrapper
const SettingsButton = PROJECT_NAME === 'Def' ? Def.SettingsButton : Time.SettingsButton
const SettingsLink = PROJECT_NAME === 'Def' ? Def.SettingsLink : Time.SettingsLink
const ThemeButton = PROJECT_NAME === 'Def' ? Def.ThemeButton : Time.ThemeButton
const ThemeButtonsWrapper =
  PROJECT_NAME === 'Def' ? Def.ThemeButtonsWrapper : Time.ThemeButtonsWrapper

const Images = {
  Def: HeaderImgDef,
  Time: HeaderImgTime,
}

const useOnWindowScroll = callback => {
  const listener = React.useRef<null | any>(null)

  React.useEffect(() => {
    if (listener.current) window.removeEventListener('scroll', listener.current)
    listener.current = window.addEventListener('scroll', callback)
    return () => {
      window.removeEventListener('scroll', listener.current)
    }
  }, [callback])
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({ toggleTheme, isDarkTheme }) => {
  const UserStore = useContext(UserStorage)
  const [isScrolled, setIsScrolled] = useState(false)

  useOnWindowScroll(() => {
    if (isScrolled && window.scrollY < 100) {
      setIsScrolled(false)
    } else if (!isScrolled && window.scrollY > 100) {
      setIsScrolled(true)
    }
  })

  const [menuOpen, setMenuOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const isAuth = !!UserStore.accessToken
  const isLanding = location.pathname === '/'
  const isAdminPaths = location.pathname.includes('admin')

  const { t, i18n } = useTranslation()
  const [openLangModal, setOpenLangModal] = useState(false)
  const handleLangMenu = () => {
    setOpenLangModal(bool => !bool)
  }

  const [visibleLogout, setVisibleLogout] = useState<boolean>(false)
  const isAdmin = UserStore.isAdmin()

  return (
    <Header className={'header'} isAuth={isAuth}>
      <LanguageModal openMenu={openLangModal} handleMenu={handleLangMenu} />
      <Content isLanding={isLanding}>
        {isLanding && PROJECT_NAME === 'Def' ? (
          <ScrollLink
            to={'main'}
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            style={{ display: 'flex', cursor: 'pointer' }}
          >
            <Logo src={Images[PROJECT_NAME].logo} alt="" />
          </ScrollLink>
        ) : (
          <Link
            to={PROJECT_NAME === 'Def' ? '/' : 'https://landing.time.investments/'}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Logo src={Images[PROJECT_NAME].logo} alt="" />
          </Link>
        )}

        <LinksWrapper>
          {isAuth &&
            !isAdminPaths &&
            headerAuthLinks.map((link, idx) => (
              <React.Fragment key={idx}>
                {link.to && (
                  <HeaderLink to={link.to} key={idx} $isSelected={location.pathname === link.to}>
                    {t(link.text)}
                  </HeaderLink>
                )}
              </React.Fragment>
            ))}
          {isAuth && isAdmin && (
            <HeaderLink
              to={!isAdminPaths ? '/admin/analytics' : '/dashboard'}
              $isSelected={
                location.pathname === (!isAdminPaths ? '/admin/analytics' : '/dashboard')
              }
            >
              {t(isAdminPaths ? 'menu.backToUser' : 'menu.adminPanel')}
            </HeaderLink>
          )}
        </LinksWrapper>

        <RightWrapper>
          <ThemeButtonsWrapper>
            <ThemeButton onClick={toggleTheme} $isActive={isDarkTheme}>
              <img src="/images/interface/header/moon.svg" alt="" />
            </ThemeButton>
            <ThemeButton onClick={toggleTheme} $isActive={!isDarkTheme}>
              <img src="/images/interface/header/sun.svg" alt="" />
            </ThemeButton>
          </ThemeButtonsWrapper>
          {!isAuth && (
            <BtnWrapper>
              <StyledButton
                onClick={() => navigate('/login')}
                variant={'text'}
                style={
                  PROJECT_NAME === 'Def'
                    ? {
                        fontSize: 16,
                        fontWeight: 600,
                        color: '#fff',
                      }
                    : {}
                }
              >
                <Def.AuthImg src="/images/interface/header/login.png" alt="" />
                <Def.AuthText>{t('auth.login')}</Def.AuthText>
              </StyledButton>
              <StyledButton
                onClick={() => navigate('/register')}
                style={
                  PROJECT_NAME === 'Def'
                    ? {
                        background: '#fff',
                        color: '#000',
                        fontSize: 16,
                        fontWeight: 600,
                        padding: PROJECT_NAME === 'Def' ? 8 : '6px 16px',
                        border: 'none',
                        paddingRight: '4px',
                      }
                    : {}
                }
              >
                <Def.AuthImg src="/images/interface/header/signup.png" alt="" />
                <Def.AuthText>{t('auth.reg_title')}</Def.AuthText>
              </StyledButton>
            </BtnWrapper>
          )}
          {!isLanding && isAuth && (
            <AppMenu onClick={() => setMenuOpen(prev => !prev)} menuOpen={menuOpen} isAdminPaths>
              <img
                width={18}
                height={18}
                alt="menu-toggle"
                src={menuOpen ? '/images/close16.svg' : '/images/interface/header/menu.svg'}
              />
            </AppMenu>
          )}
          {PROJECT_NAME === 'Def' && (
            <SettingsButton onClick={handleLangMenu}>
              <img src="/images/interface/header/language.svg" alt="" />
            </SettingsButton>
          )}
          {isAuth && (
            <SettingsLink to="/settings">
              <img src="/images/interface/header/settings.svg" alt="" />
            </SettingsLink>
          )}
          {isAuth && (
            <ExitButton onClick={() => setVisibleLogout(true)}>
              <img src={'/images/interface/header/exit.svg'} alt="" />
            </ExitButton>
          )}
        </RightWrapper>
      </Content>
      <MenuModal
        isScrolled={isScrolled}
        isAuth={isAuth}
        open={menuOpen}
        handleMenu={() => setMenuOpen(prev => !prev)}
        toggleTheme={toggleTheme}
        isDarkTheme={isDarkTheme}
      />
      {visibleLogout && <LogoutModal setIsOpen={setVisibleLogout} />}
    </Header>
  )
}

export default observer(HeaderComponent)
