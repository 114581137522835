export const marketList = [
  {
    symbol: 'BTC',
    name: 'Bitcoin',
    link: '/markets/BTCUSDT',
    tradingView: 'BYBIT:BTCUSDT',
    pair: 'BTC/USDT',
  },
  {
    symbol: 'ETH',
    name: 'Ethereum',
    link: '/markets/ETHUSDT',
    tradingView: 'BYBIT:ETHUSDT',
    pair: 'ETH/USDT',
  },
  {
    symbol: 'SOL',
    name: 'Solana',
    link: '/markets/SOLUSDT',
    tradingView: 'BYBIT:SOLUSDT',
    pair: 'SOL/USDT',
  },
  {
    symbol: 'XRP',
    name: 'Ripple',
    link: '/markets/XRPUSDT',
    tradingView: 'BYBIT:XRPUSDT',
    pair: 'XRP/USDT',
  },
  {
    symbol: 'USDC',
    name: 'USDC',
    link: '/markets/USDCUSDT',
    tradingView: 'BYBIT:USDCUSDT',
    pair: 'USDC/USDT',
  },
  {
    symbol: 'ADA',
    name: 'Cardano',
    link: '/markets/ADAUSDT',
    tradingView: 'BYBIT:ADAUSDT',
    pair: 'ADA/USDT',
  },
  {
    symbol: 'AVAX',
    name: 'Avalanche',
    link: '/markets/AVAXUSDT',
    tradingView: 'BYBIT:AVAXUSDT',
    pair: 'AVAX/USDT',
  },
  {
    symbol: 'DOGE',
    name: 'Dogecoin',
    link: '/markets/DOGEUSDT',
    tradingView: 'BYBIT:DOGEUSDT',
    pair: 'DOGE/USDT',
  },
  {
    symbol: 'BNB',
    name: 'Binance Coin',
    link: '/markets/BNBUSDT',
    tradingView: 'BYBIT:BNBUSDT',
    pair: 'BNB/USDT',
  },
  {
    symbol: 'TRX',
    name: 'Tron',
    link: '/markets/TRXUSDT',
    tradingView: 'BYBIT:TRXUSDT',
    pair: 'TRX/USDT',
  },
  {
    symbol: 'UNI',
    name: 'Uniswap',
    link: '/markets/UNIUSDT',
    tradingView: 'BYBIT:UNIUSDT',
    pair: 'UNI/USDT',
  },
  {
    symbol: 'TON',
    name: 'Toncoin',
    link: '/markets/TONUSDT',
    tradingView: 'BYBIT:TONUSDT',
    pair: 'TON/USDT',
  },
  {
    symbol: 'EOS',
    name: 'EOS',
    link: '/markets/EOSUSDT',
    tradingView: 'BYBIT:EOSUSDT',
    pair: 'EOS/USDT',
  },
  {
    symbol: 'XLM',
    name: 'XLM',
    link: '/markets/XLMUSDT',
    tradingView: 'BYBIT:XLMUSDT',
    pair: 'XLM/USDT',
  },
  {
    symbol: 'PEPE',
    name: 'PEPE',
    link: '/markets/PEPEUSDT',
    tradingView: 'BYBIT:PEPEUSDT',
    pair: 'PEPE/USDT',
  },
  {
    symbol: 'TIA',
    name: 'TIA',
    link: '/markets/TIAUSDT',
    tradingView: 'BYBIT:TIAUSDT',
    pair: 'TIA/USDT',
  },
  {
    symbol: 'APEX',
    name: 'APEX',
    link: '/markets/APEXUSDT',
    tradingView: 'BYBIT:APEXUSDT',
    pair: 'APEX/USDT',
  },
  {
    symbol: 'SUI',
    name: 'SUI',
    link: '/markets/SUIUSDT',
    tradingView: 'BYBIT:SUIUSDT',
    pair: 'SUI/USDT',
  },
  {
    symbol: 'ARB',
    name: 'ARB',
    link: '/markets/ARBUSDT',
    tradingView: 'BYBIT:ARBUSDT',
    pair: 'ARB/USDT',
  },
  {
    symbol: 'NEAR',
    name: 'NEAR',
    link: '/markets/NEARUSDT',
    tradingView: 'BYBIT:NEARUSDT',
    pair: 'NEAR/USDT',
  },
  {
    symbol: 'ALGO',
    name: 'ALGO',
    link: '/markets/ALGOUSDT',
    tradingView: 'BYBIT:ALGOUSDT',
    pair: 'ALGO/USDT',
  },
]
