import styled from 'styled-components/macro'

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  max-width: 120rem;
  width: 100%;
  box-sizing: border-box;
`
