import { FC, ReactNode, useContext, useEffect } from 'react'
import UserStorage from './storage/UserStorage/UserStorage'
import LanguageStorage from './storage/LanguageStorage/LanguageStorage'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import ProfileStorage from './storage/ProfileStorage/ProfileStorage'
import UserApi from './service/user-api/UserApi'
import MessageModal from './components/global/modal/MessageModal'
import GlobalModalStore from './storage/GlobalModalStore/GlobalModalStore'
import { FlexColumn } from './style/projectComponents'
import styled from 'styled-components/macro'
import { QueryClientProvider } from 'react-query'
import { configurePersistable } from 'mobx-persist-store'
import { queryClient } from './config/query-client'

type IAppProviderProps = {
  children?: ReactNode
}

const ModalsColumn = styled(FlexColumn)`
  position: fixed;
  top: 8.56rem;
  right: 1rem;
  z-index: 100;
  row-gap: 1rem;
`
const AppProvider: FC<IAppProviderProps> = props => {
  const { children } = props

  const LanguageStore = useContext(LanguageStorage)
  const UserStore = useContext(UserStorage)
  const ProfileStore = useContext(ProfileStorage)

  const GetProfileQuery = useQuery(
    'profile_data',
    () => UserApi.getUserDetails(UserStore.user['UserId']),
    {
      onSuccess: data => {
        ProfileStore.setProfile(data)
      },
      enabled: !!UserStore.accessToken,
    }
  )

  const { t, i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(LanguageStore.language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const GlobalModalStorage = useContext(GlobalModalStore)

  configurePersistable(
    {
      debugMode: true,
      expireIn: 86400000,
      removeOnExpiration: true,
      storage: window.localStorage,
      stringify: false,
    },
    { delay: 200, fireImmediately: false }
  )

  return (
    <QueryClientProvider client={queryClient}>
      <ModalsColumn>
        {GlobalModalStorage.windows.map(it => (
          <MessageModal {...it} key={it.id} />
        ))}
      </ModalsColumn>
      {children}
    </QueryClientProvider>
  )
}

export default observer(AppProvider)
