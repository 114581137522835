import styled from 'styled-components/macro'
import { FlexColumn, FlexRowBetween, Page } from '../../style/projectComponents'
import { OperationsComponent } from '../../components/DashboardComponents/OperationsComponent'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import AdminApi from '../../service/admin-api/AdminApi'
import { DataEmptyComponent } from '../../components/global/DataEmptyComponent'
import { CreateCurrencyForm } from '../../components/AdminComponents/AdminUserDetail/CreateCurrencyForm'
import { CreateNetworkForm } from '../../components/AdminComponents/AdminUserDetail/CreateNetworkForm'

const StyledBg = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.pageBg}
  padding: 24px;
  height: 100%;
  margin-top: 110px;
  justify-content: flex-start;
`

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  padding: 26px 16px;
  border-radius: 8px;
  max-height: 740px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`
const CreateFormsWrapper = styled(FlexRowBetween)`
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const AdminCurrencies = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { data: currencyList, isLoading } = useQuery('currency_list', () =>
    AdminApi.getCurrencies({
      skip: 0,
      take: 30,
    })
  )
  const currencyItems = currencyList?.currencies || []

  return (
    <Page>
      <StyledBg>
        <CreateFormsWrapper>
          <CreateCurrencyForm />
          <CreateNetworkForm />
        </CreateFormsWrapper>
        <OperationsWrapper>
          {currencyItems.length > 0 ? (
            <OperationsComponent
              withTopBorder
              keys={['name', 'id', 'coinGeckoId', 'usdPrice', 'actions']}
              isCreateCurrency
              operations={currencyItems}
            />
          ) : (
            <DataEmptyComponent isLoading={isLoading} />
          )}
        </OperationsWrapper>
      </StyledBg>
    </Page>
  )
}
