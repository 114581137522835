import {
  IAuthInterface,
  IAuthSigninRequest,
  IAuthSigninResponse,
  IAuthSignupRequest,
  IResetPasswordRequest,
  ITokens,
} from './models'
import { apiClient } from '../../config/serverConstants'

class AuthService implements IAuthInterface {
  async resetPassword(data: IResetPasswordRequest) {
    const response = await apiClient.post('/api/Auth/resetPassword', data)
    return response.data as string
  }

  async forgotPassword(email: string) {
    const response = await apiClient.post('/api/Auth/forgotPassword', {
      email: email,
    })
    return response.data as string
  }

  async refreshToken(data: ITokens) {
    const response = await apiClient.post('/api/Auth/refreshToken', data)
    return response.data as ITokens
  }

  async signin(data: IAuthSigninRequest) {
    const response = await apiClient.post('/api/Auth/signIn', data)
    return response.data as IAuthSigninResponse
  }

  async signup(data: IAuthSignupRequest) {
    const response = await apiClient.post('/api/Auth/signUp', data)
    return response.data as ITokens
  }

  async enableTwoFactor() {
    const response = await apiClient.post('/api/Auth/enableTwoFactor')
    return response.data as string
  }

  async disableTwoFactor(data: string) {
    const response = await apiClient.post('/api/Auth/disableTwoFactor', {
      twoFactorCode: data,
    })
    return response.data as any
  }

  async confirmTwoFactor(data: string) {
    const response = await apiClient.post('/api/Auth/confirmTwoFactor', {
      twoFactorCode: data,
    })
    return response.data as any
  }
}
const Service = new AuthService()
export default Service
