export type IGetTickersReq = {
  category: ITickerCategory
  symbol?: string
}
export type IGetOrderBookReq = {
  category: ITickerCategory
  symbol?: string
  limit?: number
}

export type IGetTickersRes = {
  result: ITickersRes
  retCode: number
  retExtInfo: {}
  retMsg: string
  time: number
}

export type IGetRecentTradesResponse = {
  result: ITradesResponse
  retCode: number
  retExtInfo: {}
  retMsg: string
  time: number
}

export type IGetOrderBookResponse = {
  result: IOrderBookResponse
  retCode: number
  retExtInfo: {}
  retMsg: string
  time: number
}

export type ITickersRes = {
  category: ITickerCategory
  list: ITicker[]
}

export type ITradesResponse = {
  category: ITickerCategory
  list: ITrade[]
}

export type IOrderBookResponse = {
  a: OrderBookItemType[]
  b: OrderBookItemType[]
  s: string
  seq: number
  ts: number
  u: number
}

export type OrderBookItemType = [string, string]

export type ITicker = {
  symbol: string
  bid1Price: string
  bid1Size: string
  ask1Price: string
  ask1Size: string
  lastPrice: string
  prevPrice24h: string
  price24hPcnt: string
  highPrice24h: string
  lowPrice24h: string
  turnover24h: string
  volume24h: string
  usdIndexPrice: string
}
export type ITrade = {
  execId: string
  symbol: string
  price: string
  size: string
  side: ITradeSide
  time: string
  isBlockTrade: boolean
}

export enum ITradeSide {
  Buy = 'Buy',
  Sell = 'Sell',
}

export enum ITickerCategory {
  spot = 'spot',
  linear = 'linear',
  inverse = 'inverse',
  option = 'option',
}

export type IBybitInterface = {
  getTickers(data: IGetTickersReq)
}
