import { FlexColumn, FlexColumnStart, FlexRow } from '../../../style/projectComponents'
import styled, { useTheme } from 'styled-components/macro'
import { FC, useContext, useEffect, useState } from 'react'
import { Close } from '../../../assets/Close'
import GlobalModalStore, { TToast } from '../../../storage/GlobalModalStore/GlobalModalStore'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

const Wrapper = styled(FlexColumnStart)<{ openModal: boolean }>`
  display: ${props => (props.openModal ? 'flex' : 'none')};
  width: 380px;
  height: 100px;
  border: solid 2px ${({ theme }) => theme.colors.lightGrayBorder};
  border-radius: 16px;
  padding: 15px 15px 25px 15px;
  background-color: ${({ theme }) => theme.colors.popupBg};
  color: ${({ theme }) => theme.colors.black};

  animation-duration: 0.5s;
  animation-name: slidein;
  @media (max-width: 480px) {
    width: 300px;
  }

  @keyframes slidein {
    from {
      margin-right: -100%;
    }

    to {
      margin-right: 0%;
    }
  }
`

const ImgContainer = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.inputBackgroundSecondary};
  width: 40px;
  height: 40px;
  border-radius: 100px;
`

const CloseContainer = styled(FlexRow)`
  width: 100%;
  align-items: end;
  justify-content: end;
  cursor: pointer;
`

const Content = styled(FlexRow)`
  gap: 20px;
  align-items: center;
`

const TextWrapper = styled(FlexColumnStart)`
  gap: 2px;

  p {
    font-weight: 700;
    font-size: 14px;
  }
`
const TimerWrapper = styled(FlexRow)`
  background: ${({ theme }) => theme.colors.inputBackgroundSecondary};
  width: 297px;
  border-radius: 20px;
  height: 5px;
  margin-top: 12px;
  justify-content: start;
  @media (max-width: 480px) {
    width: 217px;
  }
`
const Timer = styled.div`
  border-radius: 20px;
  height: 5px;
`

const MessageModal: FC<TToast> = props => {
  const { id, message, isVisible, isSuccess } = props
  const { t, i18n } = useTranslation()
  const GlobalModalStorage = useContext(GlobalModalStore)
  const theme = useTheme()

  const loadDuration = 5000
  const updateInterval = 10
  const [timerPercentage, setTimerPercentage] = useState(0)

  useEffect(() => {
    let elapsedTime = 0
    const updateProgress = () => {
      const percentage = Math.min(100, 100 - (elapsedTime / loadDuration) * 100)
      setTimerPercentage(percentage)
      elapsedTime = elapsedTime += updateInterval
      if (elapsedTime > loadDuration) {
        clearInterval(interval)
      }
    }
    const interval = setInterval(updateProgress, updateInterval)
  }, [])

  return (
    <Wrapper openModal={isVisible}>
      <CloseContainer onClick={() => GlobalModalStorage.close(id)}>
        {Close(theme.colors.black)}
      </CloseContainer>
      <Content>
        <ImgContainer>
          <img
            src={
              isSuccess
                ? '/images/interface/requests/success.svg'
                : '/images/interface/requests/failed.svg'
            }
            alt=""
          />
        </ImgContainer>
        <TextWrapper>
          <p>{isSuccess ? t('common.accepted') : t('common.errorModal')}</p>
          <p
            style={{
              fontSize: 12,
              fontWeight: 500,
              color: isSuccess ? theme.colors.blue : theme.colors.redFail,
              textAlign: 'left',
            }}
          >
            {message}
          </p>
          <TimerWrapper>
            <Timer
              style={{
                width: `${timerPercentage}%`,
                background: isSuccess ? theme.colors.blue : theme.colors.redFail,
              }}
            />
          </TimerWrapper>
        </TextWrapper>
      </Content>
    </Wrapper>
  )
}

export default observer(MessageModal)
