import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  AppTextNormal,
  AppTitleNormal,
  AssetsBlock,
  FlexColumn,
  MarketText,
  AssetsBlockHeader,
  Page,
  FlexColumnStart,
} from '../style/projectComponents'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'
import { AssetActionsItem } from '../components/AssetsComponents/AssetActionsItem'
import { useTranslation } from 'react-i18next'
import toLocaleNumber from '../utils/toLocaleNumber'
import { useQuery } from 'react-query'
import AdminApi from '../service/admin-api/AdminApi'
import { IGetCurrenciesRes } from '../service/admin-api/models'
import UserApi from '../service/user-api/UserApi'
import UserStorage from '../storage/UserStorage/UserStorage'
import ProfileStorage from '../storage/ProfileStorage/ProfileStorage'
import { TotalBalanceComponent } from '../components/DashboardComponents/TotalBalanceComponent'
import { currenciesList } from '../config/currenciesList'
import axios from 'axios'

const StyledBg = styled(FlexColumn)`
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 110px;
  padding-bottom: 20px;
  @media screen and (max-width: 450px) {
    padding: 16px;
  }
`
const Table = styled(FlexColumnStart)`
  width: 100%;

  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    max-width: available;
    overflow: auto;
    padding: 0;
    margin: 0;
  }
`
const Content = styled(FlexColumnStart)`
  @media (max-width: 768px) {
    min-width: 100%;
    width: auto;
  }
  width: 100%;
`
const AssetsPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const UserStore = useContext(UserStorage)
  const [profileData, setProfileData] = useState(null)
  const GetProfileQuery = useQuery(
    'profile_data',
    () => UserApi.getUserDetails(UserStore.user['UserId']),
    {
      onSuccess: data => {
        setProfileData(data)
      },
      enabled: !!UserStore.accessToken,
    }
  )
  const wallets = profileData?.wallets

  const { t } = useTranslation()
  const ProfileStore = useContext(ProfileStorage)

  const [currencyList, setCurrencyList] = useState<IGetCurrenciesRes>()
  const totalBalance = ProfileStore?.profile?.totalBalanceInUsd || 0
  const [userBalanceBtcValue, setUserBalanceBtcValue] = useState(0)
  const currencies = useMemo(() => currencyList?.currencies || [], [currencyList])
  useEffect(() => {
    if (currencies.length) {
      const btcPrice = currencies?.find(c => c.name.toLowerCase() === 'btc')?.usdPrice || 0
      setUserBalanceBtcValue(totalBalance / btcPrice)
    }
  }, [currencies])

  useQuery(
    'currency_list',
    () =>
      AdminApi.getCurrencies({
        skip: 0,
        take: 50,
      }),
    {
      onSuccess: data => {
        setCurrencyList(data)
      },
    }
  )

  const [currenciesRates, setCurrenciesRates] = useState(null)
  const [activeCurrencyId, setActiveCurrencyId] = useState(0)
  const currencyCode = currenciesList[activeCurrencyId].code
  const currencySymbol = currencyCode?.toUpperCase()
  const activeCurrencyRate = (currenciesRates && currenciesRates?.usd?.[currencyCode]) || 1
  useEffect(() => {
    const getCoingeckoCurrenciesUrl = `https://api.coingecko.com/api/v3/simple/price?ids=usd&vs_currencies=${currenciesList.map((cur, id) => `${cur.code}${id < currenciesList.length - 1 ? '%2C' : ''}`).join('')}`
    // TODO: перенести валюты на наш бэк
    // Перенести смену валюты в кэш
    axios
      .get(getCoingeckoCurrenciesUrl)
      .then(response => {
        setCurrenciesRates(response.data)
      })
      .catch(err => {
        console.error(err)
      })
  }, [])

  return (
    <Page style={{ justifyContent: 'start' }}>
      <StyledBg>
        <AssetsBlockHeader style={{ flexDirection: 'column', marginBottom: 32 }}>
          <AppTitleNormal style={{ marginBottom: 10 }}>{t('assets.totalAssets')}</AppTitleNormal>
          <TotalBalanceComponent
            title={`${toLocaleNumber(totalBalance)}`}
            symbol={currencySymbol}
            currenciesRates={currenciesRates}
            activeCurrencyId={activeCurrencyId}
            setActiveCurrencyId={setActiveCurrencyId}
          />
          {totalBalance !== undefined && (
            <AppTextNormal>
              {userBalanceBtcValue ??
                userBalanceBtcValue?.toLocaleString('en', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                })}{' '}
              BTC
            </AppTextNormal>
          )}
        </AssetsBlockHeader>
        <AssetsBlock>
          <Table>
            <Content>
              <AssetsBlockHeader>
                <MarketText style={{ minWidth: '110px', width: 110 }}>
                  {t('assets.asset')}
                </MarketText>
                <MarketText style={{ width: 130 }}>{t('assets.balance')}</MarketText>
                <MarketText style={{ width: 175, paddingLeft: 20 }}>
                  {t('assets.actions')}
                </MarketText>
              </AssetsBlockHeader>
              {currencies?.map(cur => (
                <AssetActionsItem
                  usdPrice={cur.usdPrice * activeCurrencyRate}
                  title={cur.name}
                  currencySymbol={currencySymbol}
                  fullName={cur?.description}
                  id={cur.id}
                  key={cur.id}
                  networks={cur.availableNetworksForDeposit}
                  userBalance={
                    wallets?.find(it => it.currency.toLowerCase() === cur.name.toLowerCase())
                      ?.balance || 0
                  }
                />
              ))}
            </Content>
          </Table>
        </AssetsBlock>
      </StyledBg>
    </Page>
  )
}

export default observer(AssetsPage)
