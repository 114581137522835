import React from 'react'
import { FlexRow } from '../../style/projectComponents'
import styled from 'styled-components/macro'
import { mockPricesData } from '../../config/mockPricesData'

const Block = styled(FlexRow)`
  height: 37.5px;
  background: linear-gradient(100.72deg, #ff8a00, #d449f7);
  width: 100vw;
  white-space: nowrap;
  transition: all;
`
const TickerLine = styled(FlexRow)`
  width: 100%;

  h2 {
    color: #fff;
    margin: 0 24px;
    font-size: 16px;
  }

  animation: tickerScroll 30s linear infinite normal;
  @keyframes tickerScroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`

export const AnimatedPriceTicker: React.FC = () => {
  return (
    <Block>
      <TickerLine>
        {mockPricesData.map(coinData => (
          <h2 key={coinData.id}>
            {coinData?.symbol.toUpperCase()} ${coinData?.current_price} ($
            {coinData?.price_change_percentage_24h.toFixed(2)} %)
          </h2>
        ))}
      </TickerLine>
    </Block>
  )
}
