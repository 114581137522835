import { CurrencySwitch, FlexRow } from '../../style/projectComponents'
import React from 'react'
import styled from 'styled-components/macro'
import { currenciesList } from '../../config/currenciesList'

const Block = styled(FlexRow)`
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
`

interface ChangeLanguageProps {
  handleMenu: () => void
  activeCurrencyId: number
  setActiveCurrencyId: (number) => void
}

export const ChangeCurrency: React.FC<ChangeLanguageProps> = ({
  handleMenu,
  activeCurrencyId,
  setActiveCurrencyId,
}) => {
  return (
    <Block>
      {currenciesList.map((it, id) => (
        <CurrencySwitch
          key={id}
          onClick={() => {
            setActiveCurrencyId(id)
            handleMenu()
          }}
          active={id === activeCurrencyId}
        >
          {it.code?.toUpperCase()}
        </CurrencySwitch>
      ))}
    </Block>
  )
}
