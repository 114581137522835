import {
  CloseModalButton,
  FlexColumnStart,
  StyledButton as StyledButtonDef,
} from '../../../style/projectComponents'
import { StyledButton as StyledButtonTime } from '../../../style/projectTimeComponents'
import styled from 'styled-components/macro'
import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import { PROJECT_NAME } from '../../../config/serverConstants'

const StyledButton = PROJECT_NAME === 'Def' ? StyledButtonDef : StyledButtonTime

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: ${({ theme }) => theme.colors.modalBg};
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 130;
`

const Content = styled(FlexColumnStart)`
  background: ${({ theme }) => theme.colors.gray050};
  border-radius: 16px;
  padding: 20px 25px;
  position: relative;
  width: 416px;
  align-items: center;

  @media (max-width: 480px) {
    padding: 15px;
    width: calc(100% - 50px);
    align-items: center;
  }
`

const InnerWrapper = styled(FlexColumnStart)`
  row-gap: 15px;
  width: calc(100% - 100px);
  color: ${({ theme }) => theme.colors.black};

  h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
    width: 100%;
  }

  p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`

export const LogoutModal: FC<{ setIsOpen?: any }> = ({ setIsOpen }) => {
  const { t } = useTranslation()
  const UserStore = useContext(UserStorage)

  return (
    <StyledForm
      onClick={e => {
        if (e.target === e.currentTarget) {
          setIsOpen(false)
        }
      }}
    >
      <Content>
        <CloseModalButton onClick={() => setIsOpen(false)}>
          <img src={'/images/ic_cancel.svg'} />
        </CloseModalButton>

        <InnerWrapper>
          <h3>{t('menu.exit')}</h3>
          <p>{t('menu.exitConfirm')}</p>
          <StyledButton
            onClick={() => UserStore.logout()}
            variant="primary"
            style={{ width: '100%', height: 44, marginTop: '5px' }}
          >
            {t('menu.logout')}
          </StyledButton>
          <StyledButton
            variant="black"
            onClick={() => setIsOpen(false)}
            style={{ width: '100%', height: 44 }}
          >
            {t('menu.cancel')}
          </StyledButton>
        </InnerWrapper>
      </Content>
    </StyledForm>
  )
}
